import { useDispatch } from "react-redux";
import { AppDispatch } from "../redux/store";
import "./service.css";
import { CapsuleTabs, Card, ErrorBlock, Grid,  Space, TabBar } from "antd-mobile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppOutline } from "antd-mobile-icons";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { fetchCommands, fetchEnableService, fetchServiceById, resetComamnds, resetEnableService, resetSelectedCommand, selectCommands, selectCommandstatus, selectService, selectServiceEnable } from "../service/service";
import { Command } from "../models/plate";
import { resetCommand } from "../service/client";
import Header from "../components/header/header";
import { useEffect, useState } from "react";
import getTotal from '../helper/CommandHelper';
import logging from '../helper/LoggingHelper';
import { ScrollContent } from '../components/misc/scrollcontent';
import { CMenu } from '../components/menu/menu';

const tabs = [
  {
    key: "home",
    title: "Home",
    icon: <AppOutline />,
  },
  {
    key: "Cuisine",
    title: "Cuisine",
    icon: <FontAwesomeIcon icon={"plate-wheat"} />,
  },
  {
    key: "Bar",
    title: "Bar",
    icon: <FontAwesomeIcon icon={"martini-glass-citrus"} />,
  },
];

type DisplayCommandProp = {
  item: Command;
  selectCommand: (item: Command) => void;
};

const DisplayCommand = ({ item, selectCommand }: DisplayCommandProp) => {
  return (
    <Card className='table-info' key={item.id} onClick={() => selectCommand(item)}>
      <div className='display-command'>
        <div className='icon'>{item.tableName}</div>
        <div className='table'>{item.nbPersons} pers. </div>
        <div>{getTotal(item)} &euro;</div>
      </div>
    </Card>
  );
};

export type DisplayAddProps = {
  onClick: () => void;
};

const DisplayAdd = ({onClick }: DisplayAddProps) => {
  return (
    <Card className="table-add" key="add-command" style={{ backgroundColor: "#25a244" }} 
    onClick={() => onClick()}>
      <div>
        <FontAwesomeIcon icon="plus-circle" size="2x" color="white"></FontAwesomeIcon>
        <div className="description">Nouvelle table</div>
      </div>
    </Card>
  );
};



const ServicePage = () => {
  const navigate = useNavigate();
  let { id } = useParams<string>();
  const commands = useSelector(selectCommands);
  const dispatch = useDispatch<AppDispatch>();
  const commandsStatus = useSelector(selectCommandstatus);
  const serviceEnable = useSelector(selectServiceEnable);
  const service = useSelector(selectService);

  let [serviceId, setServiceId] = useState<string>();

  useEffect(() => {
  logging.debug(`Changement de l'id du service : ${id}`);
   if(id){
     dispatch(fetchServiceById(id!));
     dispatch(fetchCommands(id!));
     setServiceId(id);
   }else{
     dispatch(fetchEnableService());
   }
  }, [id]);
 
  useEffect(() => {
    logging.debug(`ServiceId :  ${serviceId}, ServiceEnable Id : ${serviceEnable?.id}`);
    if(serviceEnable){
      logging.debug(`Changement detecte. Service : ${serviceEnable.id}`);
      navigate(`/service/${serviceEnable.id}`);
    }
    return () => {
      dispatch(resetEnableService());
      dispatch(resetComamnds());
      setServiceId(undefined);
    };
  }, [serviceEnable])


  const clearContext = () =>{
    dispatch(resetComamnds());
    dispatch(resetCommand());
    dispatch(resetSelectedCommand());
  }
  
  const selectCommand = (command: Command) => {
    clearContext()
    navigate(`/service/${serviceId}/commande/${command.id}`);
  };

  const getSortedCommands = (status: any) => {
    if(commands){
      const comms = [...commands].filter((it) => it.status === status);
      return comms.sort((a: Command, b: Command) => a.tableName.localeCompare(b.tableName));
    }
    return [];
  };

  const navigateBar = (key: string) => {
    logging.debug(`Action : ${key} `)
    switch (key) {
      case "home":
        navigate('/welcome');
        break;
      case "Cuisine":
        navigate(`/service/${serviceId}/cuisine`);
        break;
      default:
        navigate(`/service/${serviceId}/bar`);
    }
  };


  const displayNewCommand = () => {
    clearContext();
    navigate(`/service/${serviceId}/commande`)
  }


  return (
    <div className='service'>
      <Header hasPrevious={false}>
        <div style={{ flex: 'auto' }}>Service {commands && <span>({commands.length})</span>}</div>
        <CMenu />
      </Header>
      <ScrollContent compoenentsHeight={90}>
        <section>
          {serviceId && (
            <CapsuleTabs>
              <CapsuleTabs.Tab title='En cours' key='in_progress'>
                <Grid columns={1} gap={6}>
                  {service && service.state === 'ENABLE' && (<DisplayAdd onClick={displayNewCommand} />)}
                  {getSortedCommands('IN_PROGRESS').map((tableInfo) => {
                    return <DisplayCommand item={tableInfo} selectCommand={selectCommand} key={tableInfo.id} />;
                  })}
                </Grid>
              </CapsuleTabs.Tab>
              <CapsuleTabs.Tab title='Terminé' key='end'>
                <Grid columns={1} gap={6}>
                  {getSortedCommands('ENDED').map((tableInfo) => {
                    return <DisplayCommand item={tableInfo} selectCommand={selectCommand} key={tableInfo.id} />;
                  })}
                </Grid>
              </CapsuleTabs.Tab>
            </CapsuleTabs>
          )}
          {!serviceId && (
            <Space block direction='vertical' style={{ '--gap': '16px' }}>
              <ErrorBlock
                status='disconnected'
                title='Aucun service actif'
                description='Pour activer un service, aller dans la page Gestion des services'
              />
            </Space>
          )}
        </section>
      </ScrollContent>
      <div className='bottom'>
        <TabBar activeKey={null} onChange={(item) => navigateBar(item)}>
          {tabs.map((item) => (
            <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
          ))}
        </TabBar>
      </div>
    </div>
  );
};

export default ServicePage;
