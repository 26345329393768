import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover } from 'antd-mobile';
import { Action } from 'antd-mobile/es/components/popover';
import { useNavigate } from 'react-router-dom';

const CMenu = () => {
  const navigate = useNavigate();

   const actions: Action[] = [
     { key: 'service', icon: <FontAwesomeIcon icon='utensils' />, text: 'Service actif' },
     { key: 'services', icon: <FontAwesomeIcon icon='list' />, text: 'Gestion des services' },
     { key: 'params', icon: <FontAwesomeIcon icon='gear' />, text: 'Parametrage' },
     { key: 'prints', icon: <FontAwesomeIcon icon='print' />, text: 'Impression' },
   ];


  const onSelectMenu = (item: Action) => {
    switch (item.key) {
      case 'service':
        navigate('/service');
        break;
      case 'services':
        navigate('/service-list');
        break;
      case 'params':
        navigate('/params');
        break;
      case 'prints':
        navigate('/prints');
        break;
    }
  };

  return (
    <Popover.Menu trigger='click' placement='bottom-end' actions={actions} onAction={onSelectMenu}>
      <div style={{ flex: 'initial', width: '40px' }}>
        <FontAwesomeIcon icon={'bars'} />
      </div>
    </Popover.Menu>
  );
};

export { CMenu };
