import { Button, Form, Grid, Input, Mask, Selector, Toast } from 'antd-mobile';
import Header from '../../components/header/header'
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useEffect, useState } from 'react';
import { PrinterModel, Status, fetchPrinter, printerSaveState, resetPrinter, resetPrinters, savePrinter, singlePrinter, singlePrinterState } from '../../service/printer';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import logging from '../../helper/LoggingHelper';
import { useSelector } from 'react-redux';

const printCategories = [
  { id: 'bar', label: 'Bar' },
  { id: 'cuisine', label: 'Plat' },
  { id: 'bill', label: 'Addition' },
];

const Printer = () =>{
    const navigate = useNavigate();
    const dispatch = useDispatch < AppDispatch>();
    let {id} = useParams<string>();
    let saveState  = useSelector(printerSaveState);
    let [status, setStatus] = useState<Status>();
    let printerState = useSelector(singlePrinterState);
    let printer = useSelector(singlePrinter);
    let [formId, setFormId] = useState<string>();

    const [form] = Form.useForm();

    useEffect(() => {
        setStatus(saveState);
        if(saveState === 'failed'){
          Toast.show({
            content: 'Probleme lors de la sauvegarde',
          });
        }else if(saveState === 'sucess'){
          dispatch(resetPrinters())
          navigate("/printers");
        }
    }, [saveState])

    useEffect(() =>{
      if(id === 'new'){
        setFormId(uuidv4());
      }else{
        setFormId(id);
        dispatch(fetchPrinter(id!));
      }
    }, [id]);

    useEffect(() => {
        form.setFieldValue("name", "");
        form.setFieldValue("ip", "");
        form.setFieldValue('categories', []);
        dispatch(resetPrinter({}));
    });

    useEffect(() => {
        if(printer){
          form.setFieldValue('name', printer.name);
          form.setFieldValue('ip', printer.ip);
          form.setFieldValue('categories', printer.categories);
        }
    }, [singlePrinterState, printer]);

    const onSubmit = () =>{
        const printer = {
          id: formId,
          name: form.getFieldValue('name'),
          ip: form.getFieldValue('ip'),
          categories: form.getFieldValue('categories'),
        } as PrinterModel;
         logging.debug(`Sauvegarde de l'imprimante : ${JSON.stringify(printer)}`);
        dispatch(savePrinter(printer))
    }
    
    const pickerData = printCategories.map((it) => {
    return { label: it.label, value: it.id };
    });

    return (
      <div>
        <Header hasPrevious={true} title='Conf. Imprimante' redirectUrl='/printers' />
        <div className='body'>
          {status == "loading" && ( <Mask/>)}
          <Form
            key={id}
            layout='vertical'
            mode='card'
            form={form}
            footer={
              <Grid columns={2} gap={4}>
                <Button block color='danger' size='large' onClick={() => navigate('/printers')}>
                  Annuler
                </Button>
                <Button block type='submit' color='success' size='large' onClick={() => onSubmit()}>
                  Enregitrer
                </Button>
              </Grid>
            }
          >
            <Form.Item name='name' label='Nom'>
              <Input placeholder='nom' type='string' />
            </Form.Item>

            <Form.Item name='ip' label='Adresse IP'>
              <Input placeholder='192.0.0.10' type='string'/>
            </Form.Item>
            <Form.Item label="Type d'impression" name='categories'>
              <Selector className='sub-category' columns={2} options={pickerData} multiple={true} />
            </Form.Item>
          </Form>
        </div>
      </div>
    );
}

export {Printer}