import { useEffect, useRef, useState } from 'react';

interface ScrollContentProps {
  children?: React.ReactNode;
  compoenentsHeight: number;
}

const ScrollContent = ({ children, compoenentsHeight }: ScrollContentProps) => {
  let [height, setHeight] = useState<number>(200);

  const scrollDiv = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < window.innerHeight) {
        const maxHeight = window.innerHeight - compoenentsHeight;
        setHeight(maxHeight);
      } else {
        const maxHeight = window.innerHeight - compoenentsHeight;
        setHeight(maxHeight);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div style={{ height: height + 'px', overflow: 'auto' }} ref={scrollDiv}>
      {children}
    </div>
  );
};

export { ScrollContent };
