import { useEffect, useState } from 'react';
import { Command, PlatItem, PlateDetail } from '../models/plate';
import { Grid, TabBar } from 'antd-mobile';
import { GridItem } from 'antd-mobile/es/components/grid/grid';
import { AppDispatch } from '../redux/store';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { addPlate,  selectEditCommand, selectItems, selectTableInfo, setCurrentGroup } from '../service/client';
import { useNavigate, useParams } from 'react-router-dom';
import {  selectCommandStatusSave } from '../service/service';
import Header from '../components/header/header';
import { getLastGroup } from '../helper/GroupHelper';
import { AppOutline } from 'antd-mobile-icons';
import getTotal from '../helper/CommandHelper';
import logging from '../helper/LoggingHelper';

import { printBill } from '../service/printer';

const HEADER_HEIGHT = 40;
const FOOTER_HEIGHT = 50;
const PAD_HEIGHT = 40 + 80 * 4 + 0 * 40;

const epson = (window as any).epson;

type RowBillItem = {
  detail: PlateDetail;
  quantity: number;
  unitPrize: number;
}

const DEFAULT_TABS = [
  {
    key: 'home',
    title: 'Home',
    icon: <AppOutline />,
  },
];

const BillPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  let { serviceId } = useParams<string>();
  const navigate = useNavigate();
  let [height, setHeight] = useState<number>(200);
  let [tabs, setTabs] = useState(DEFAULT_TABS);
  let [padVisible, setPadVisible] = useState(true);
  const commandSaveStatus = useSelector(selectCommandStatusSave);

  let [printView, setPrintView] = useState("");
  const [gridColumns, setGridColumns] = useState(window.innerWidth < window.innerHeight ? 1 : 2);

  const comm = useSelector(selectEditCommand);
  const items = useSelector(selectItems);
  const clientInfo = useSelector(selectTableInfo);

  useEffect(() => {
    if (commandSaveStatus === 'success') {
      navigate(`/service/${serviceId}`);
    }
  }, [commandSaveStatus]);

  const handleResize = () => {
    if (window.innerWidth < window.innerHeight) {
      const coeff =0;
      const maxHeight = window.innerHeight - PAD_HEIGHT * coeff - HEADER_HEIGHT - FOOTER_HEIGHT;
      setHeight(maxHeight);
      setGridColumns(1);
    } else {
      const maxHeight = window.innerHeight - HEADER_HEIGHT - FOOTER_HEIGHT;
      setHeight(maxHeight);
      setGridColumns(2);
    }
  };


  const createBillLines = (comm: Command) =>{
    return comm.items.reduce((stack, current) => {
       const key = current.originalPlate?.id! + '_' + Number(current.unitPrize || current.originalPlate?.prize || 0);
       if (stack.get(key) === undefined) {
         stack.set(key, {
           detail: current.originalPlate!,
           quantity: 0,
           unitPrize: Number(current.unitPrize || current.originalPlate?.prize || 0),
         });
       }
       stack.get(key)!.quantity += current.quantity;
       return stack;
     }, new Map<String, RowBillItem>());
  }
  

  useEffect(() => {
    if (comm === undefined) {
      navigate(`/service/${serviceId}`);
      return;
    }

    const epson = (window as any).epson;
   
    const printer = new epson.ePOSBuilder();
    
    printer.addFeedLine(1);
    printer.addTextAlign(printer.ALIGN_CENTER);
    printer.addText('Auberge du Catsberg\n');
    printer.addText('1487 Route de l\'Abbaye\n');
    printer.addText('59270 Godewaersvelde\n');

    printer.addFeedLine(1);
    
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    } as any;

    const day = new Date().toLocaleDateString('fr-FR', options);

    printer.addText("  " + day.charAt(0).toUpperCase() + day.substring(1) +"\n");
    printer.addFeedLine(1);

    printer.addTextAlign(printer.ALIGN_LEFT);
    printer.addText(` Table ${comm.tableName}   ${comm.nbPersons} pers. \n\n`);
   

    const stack = createBillLines(comm)

    const  taxes = Array.from(stack.values())
    .reduce((acc, current) =>{
      const vat = Array.isArray(current.detail.vat) ? current.detail.vat[0] as number : (current.detail.vat || 10);
      if(acc.get(vat) == undefined){
        acc.set(vat, 0);
      }
      console.log(vat, current.quantity * current.unitPrize);
      acc.set(vat, acc.get(vat)! + current.quantity * current.unitPrize)
      console.log(acc);
      return acc;
    }, new Map<number, number>())

    console.log(stack);
    stack.forEach((value, key) =>{
       printer.addTextPosition(10);
        const label= `${value.quantity} x ${value.detail?.name}`;
        const prize = (value.quantity * (value.unitPrize)).toFixed(2);
        const spaces = ' '.repeat(40 - label.length - prize.length)
        printer.addText(`${label}${spaces}${prize} E\n`);
    });
    /**
    comm.items.forEach(it =>{
        printer.addTextPosition(10);
        const label= `${it.quantity} x ${it.originalPlate?.name}`;
        const prize = (it.quantity * (it.unitPrize || it.originalPlate?.prize || 0)).toFixed(2);
        const spaces = ' '.repeat(40 - label.length - prize.length)
        printer.addText(`${label}${spaces}${prize} E\n`);
    })
    **/
    
    printer.addFeedLine(1);

    const totalPrize = getTotal(comm) + ' E';
    const spaces = ' '.repeat(41 - 'TOTAL'.length - totalPrize.length);
    
    printer.addTextStyle(false, false, true, printer.COLOR_1);
    printer.addTextSize(1, 2);
    printer.addText(`  TOTAL${spaces}${totalPrize}\n`);
    
    printer.addTextSize(1, 1);
    printer.addFeedLine(1);
    printer.addHLine(10, 526, printer.LINE_MEDIUM);
    printer.addTextAlign(printer.ALIGN_LEFT);
    printer.addTextPosition(10);
    
    printer.addTextStyle(false, false, true, printer.COLOR_1);

    Array.from(taxes.keys())
   .sort()
   .forEach(key => {
     const value = taxes.get(key)!;
     const label = `  TVA ${key} % :`;
     const taxe = ((value / (100 + key)) * key).toFixed(2);
     const spaces = ' '.repeat(41 - label.length - taxe.toString().length);
     printer.addText(`${label}${spaces}${taxe} E\n`);
   });
   
    printer.addHLine(10, 526, printer.LINE_MEDIUM);

    printer.addFeedLine(2);
    printer.addTextAlign(printer.ALIGN_CENTER);
    printer.addText(` MERCI DE \n`);
    printer.addText(` VOTRE VISITE \n`);
    printer.addCut(printer.CUT_FEED);
    
    const data = printer.toString();
    setPrintView(data);
    
    dispatch(printBill(data));

    dispatch(setCurrentGroup(getLastGroup(comm.items)));
    handleResize();
    window.addEventListener('resize', handleResize);
  }, [comm]);

  const addItem = (item: PlatItem) => {
    dispatch(addPlate(item));
  };


  useEffect(() => {
    handleResize();
  }, [padVisible, comm]);

  const navigateBar = (key: string) => {
    logging.debug(`Action : ${key}`);
    switch (key) {
      case 'home':
        navigate(`/service/${serviceId}/commande/${comm.id}`);
        break;
      default:
      //   navigate(`/service/${id}/bar`);
    }
  };

  return (
    clientInfo && (
      <div className='app'>
        <Header hasPrevious={true}>
          <div style={{ display: 'flex', flexDirection: 'row' }} className='header-commande'>
            <div> Table {clientInfo}</div>
            <div> {comm.nbPersons} pers.</div>
            <div> {getTotal(comm)} &euro;</div>
          </div>
        </Header>
        <div className='body'>
          <Grid columns={gridColumns}>
            <GridItem style={{ backgroundColor: '#efefef' }}>
              <textarea rows={40} cols={40} defaultValue={printView}></textarea>
            </GridItem>
          </Grid>
        </div>
        <div className='bottom'>
          <TabBar activeKey={null} onChange={(item) => navigateBar(item)}>
            {tabs.map((item) => (
              <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
            ))}
          </TabBar>
        </div>
      </div>
    )
  );
};

export { BillPage };
