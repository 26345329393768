import "./App.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { Route, Routes } from "react-router-dom";
import { CommandePage } from "./pages/command";
import PlateCommand from "./components/plate/plate-command";
import { fas } from "@fortawesome/free-solid-svg-icons";
import NouvelleTablePage from "./pages/nouvelle-table";
import WelcomePage from "./pages/welcome";
import ParamWelcome from "./pages/paramWelcome";
import ConfigDrink from "./pages/configuration/config-drink";
import ConfigPlate from "./pages/configuration/config-plate";
import ConfigDessert from "./pages/configuration/config-desserts";
import EditPlate from "./pages/configuration/edit-plate";
import ServiceListPage from "./pages/service-list";
import ServicePage from "./pages/service";
import ConfigTopic from "./pages/configuration/config-topics";
import CuisinePage from "./pages/cuisine-page";
import BarPage from "./pages/bar-page";
import { BillPage } from './pages/bill';
import { PrintJobs } from './pages/print/jobs';
import {  Printers } from './pages/print/printers';
import { Printer } from './pages/print/edit-printer';
import ConfigStarter from './pages/configuration/config-entree';

library.add(fas);

function App() {
  return (
    <>
      <Routes>
        <Route path='/welcome' element={<WelcomePage />} />
        <Route path='/service/:id' element={<ServicePage />} />
        <Route path='/service' element={<ServicePage />} />
        <Route path='/service-list' element={<ServiceListPage />} />
        <Route path='/plate' element={<PlateCommand />} />
        <Route path='/commande' element={<CommandePage />} />
        <Route path='/service/:serviceId/commande/:commandId/plate' element={<PlateCommand />} />
        <Route path='/service/:serviceId/commande/:commandId/plate/:plateId' element={<PlateCommand />} />
        <Route path='/service/:serviceId/commande/:commandId' element={<CommandePage />} />
        <Route path='/service/:serviceId/bill/:commandId' element={<BillPage />} />
        <Route path='/service/:serviceId/commande/' element={<NouvelleTablePage />} />
        <Route path='/service/:serviceId/cuisine' element={<CuisinePage />} />
        <Route path='/service/:serviceId/bar' element={<BarPage />} />
        <Route path='/nouvelle-table' element={<NouvelleTablePage />} />
        <Route path='/params' element={<ParamWelcome />}></Route>
        <Route path='/params/boisson' element={<ConfigDrink />}></Route>
        <Route path='/params/plat' element={<ConfigPlate />}></Route>
        <Route path='/params/entree' element={<ConfigStarter />}></Route>
        <Route path='/params/topic' element={<ConfigTopic />}></Route>
        <Route path='/params/dessert' element={<ConfigDessert />}></Route>
        <Route path='/params/edit' element={<EditPlate />}></Route>
        <Route path='/params/edit/:id' element={<EditPlate />}></Route>
        <Route path='/params/:type/:id' element={<EditPlate />}></Route>
        <Route path='/prints' element={<PrintJobs />}></Route>
        <Route path='/printers' element={<Printers />}></Route>
        <Route path='/printers/:id' element={<Printer />}></Route>
        <Route path='*' element={<ServicePage />} />
      </Routes>
    </>
  );
}

export default App;
