import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { CategoryModel, PlateDetail, SubCategoryModel } from "../models/plate";
import cacheService from "./storage";

export const categories: CategoryModel[] = [
  {
    category: 'boisson',
    order: 1,
    subCategories: [
      { name: 'aperitif', order: 10, type: 'B' },
      { name: 'aperitif maison', order: 20, type: 'B' },
      { name: 'cocktail', order: 30, type: 'B' },
      { name: 'biere pression', order: 40, type: 'B' },
      { name: 'biere', order: 41, type: 'B' },
      { name: 'vin', order: 42, type: 'B' },
      { name: 'soft', order: 50, type: 'B' },
      { name: 'boissons chaudes', order: 60, type: 'B' },
      { name: 'digestif', order: 70, type: 'B' },
    ],
    hasTopics: false,
    hasSauce: false,
    mainPlate: true,
    hasIceScream: false,
    hasIce: true,
    printType: 'bar',
    topics: [
      {
        name: 'topic',
        subName: 'sirop',
        label: 'Sirop',
      },
      {
        name: 'topic',
        subName: 'sirop alcoolise',
        label: 'Sirop alcoolise',
      },
      {
        name: 'topic',
        subName: 'parfum the',
        label: 'Parfum the/tisane',
      },
      {
        name: 'topic',
        subName: 'cocktail',
        label: 'Acc. Coktail',
      },
    ],
  },
  {
    category: 'entree',
    order: 2,
    subCategories: [{ name: 'entree', order: 1, type: 'P' }],
    hasTopics: true,
    hasSauce: true,
    mainPlate: true,
    hasIceScream: false,
    hasIce: false,
    printType: 'cuisine',
  },
  {
    category: 'plat',
    order: 3,
    subCategories: [
      { name: 'Sauce', order: 4, type: 'S' },
      { name: 'Menu Enfant', order: 5, type: 'ME' },
      { name: 'Classique', order: 3, type: 'P' },
      { name: 'Piece boucher', order: 2, type: 'P' },
      { name: 'Brasserie', order: 1, type: 'P' },
    ],
    hasTopics: true,
    hasSauce: true,
    mainPlate: true,
    hasIceScream: false,
    hasIce: false,
    printType: 'cuisine',
  },
  {
    category: 'dessert',
    order: 4,
    subCategories: [
      { name: 'coupe', order: 1, type: 'D' },
      { name: 'gateau', order: 2, type: 'D' },
      { name: 'accompagnement', order: 3 },
      { name: 'supplement glace', order: 4, label: 'Supp. glace' },
    ],
    hasTopics: false,
    hasSauce: false,
    mainPlate: true,
    hasIceScream: true,
    hasIce: false,
    printType: 'cuisine',
    topics: [
      {
        name: 'dessert',
        subName: 'accompagnement',
        label: 'Supplément',
      },
      {
        name: 'dessert',
        subName: 'supplement glace',
        label: 'Supp. Glace',
      },
      {
        name: 'topic',
        subName: 'parfum the',
        label: 'Parfum the/tisane',
      },
      {
        name: 'topic',
        subName: 'cocktail',
        label: 'Acc. Alcool',
      },
    ],
  },
  {
    category: 'topic',
    subCategories: [
      { name: 'accompagnement plat', order: 1, label: 'Accompagnement' },
      { name: 'boule de glace', order: 1, label: 'Boule de glace', auto: true },
      { name: 'accompagnement dessert', order: 1, label: 'Accompagnement dessert' },

      { name: 'sirop', order: 1, label: 'Sirop alcoolise', auto: true },
      { name: 'sirop alcoolise', order: 1, label: 'Sirop alcoolise', auto: true },
      { name: 'parfum the', order: 1, label: 'Parfum the/tisane', auto: true },
      { name: 'cocktail', order: 1, label: 'Acc. Coktail', auto: true },
    ],
    hasTopics: false,
    hasSauce: false,
    mainPlate: false,
    hasIceScream: false,
    hasIce: false,
    order: 100,
  },
];

export type PlateState = {
  plates: PlateDetail[];
  filteredPlates: PlateDetail[];
  categories: Category[];
  status?: string;
  error?: string;
  selectedCategory?: Category;
  statusDetail?: string;
  selectedPlate?: PlateDetail;
};

export type Category = {
  category: string;
  subCategories: SubCategoryModel[];
};

export const fetchDetailPlate = createAsyncThunk("plates/fetchDetailPlate", async (id: string) => {
  let items = await cacheService.getItem<PlateDetail[]>("PLATES");
  let ret: PlateDetail;
  if (items) {
    const f = items.payload.filter((it) => it.id === id)!;
    if (f.length === 1) {
      ret = f[0];
    }
  }
  return Promise.resolve(ret!);
});

export const fetchPlates = createAsyncThunk("plates/fetchPlates", async () => {
  var item = await cacheService.getItem<PlateDetail[]>("PLATES");
  try {
    if (item && !item.isExpired) {
      return Promise.resolve(item.payload);
    }
    var query = await fetch(`${process.env.REACT_APP_URL_SERVER}/plates`);
    var ret = await query.json();
    await cacheService.putItem("PLATES", ret);
    return ret as PlateDetail[];
  } catch (e) {
    if (!item) {
      throw e;
    }
  }
  return Promise.resolve(item.payload);
});

const initialState: PlateState = {
  plates: [],
  filteredPlates: [],
  categories: [],
  status: "idle",
  statusDetail: "idle",
};

const refreshState = (state: any, plates: PlateDetail[]) => {
  const categories: Record<string, Category> = {};
  plates.forEach((plate) => {
    if (categories[plate.category] === undefined) {
      categories[plate.category] = {
        category: plate.category,
        subCategories: [],
      };
    }
    if (plate.subCategory) {
      //TODO Humm... voir probleme suite modification structure de la sous categorie
      categories[plate.category].subCategories = [...categories[plate.category].subCategories];
      //categories[plate.category].subCategories = [...categories[plate.category].subCategories, plate.subCategory];
    }
  });
  state.categories = [];
  for (var name in categories) {
    const c = categories[name];
    c.subCategories = c.subCategories.filter((value, index, self) => self.indexOf(value) === index);
    state.categories = [...state.categories, c];
  }

  state.plates = plates;
  state.filteredPlates = plates.filter((it) => it.category === state.categories[0].name);
  state.selectedCategory = state.categories[0];
};

const plateSlice = createSlice({
  name: "plates",
  initialState,
  reducers: {
    addPlate: (state, { type, payload }) => {
      const items = [...state.plates.filter((it) => it.id !== payload.id), payload];
      cacheService.putItem("PLATES", items);
      refreshState(state, items);
    },
    filteredPlate: (state, { type, payload }) => {
      if (payload) {
        let [category, subCategory] = payload;
        let plates: PlateDetail[] = state.plates;
        if (subCategory && subCategory !== "") {
          state.filteredPlates = state.plates.filter((it) => it.subCategory === subCategory);
        } else if (category !== undefined) {
          plates = state.plates.filter((it) => it.category === category.category);
          state.filteredPlates = plates;
          state.selectedCategory = category;
        }
        if (state.filteredPlates.length === 0) {
          state.filteredPlates = plates;
        }
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchDetailPlate.pending, (state, action) => {
        state.statusDetail = "loading";
      })
      .addCase(fetchDetailPlate.fulfilled, (state, action) => {
        state.statusDetail = "succeeded";
        state.selectedPlate = action.payload;
      })
      .addCase(fetchPlates.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchPlates.fulfilled, (state, action) => {
        state.status = "succeeded";
        refreshState(state, action.payload);
      })
      .addCase(fetchPlates.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { filteredPlate, addPlate } = plateSlice.actions;

export const selectAllPlates = (state: any) => {
  return state.plateReducer.filteredPlates as PlateDetail[];
};

export const selectPlates = (state: any) => {
  return state.plateReducer.plates as PlateDetail[];
};

export const selectCategories = (state: any) => {
  return state.plateReducer.categories as Category[];
};

export const selectPlateStatus = (state: any) => {
  return state.plateReducer.status as string;
};

export const selectStatusDetail = (state: any) => {
  return state.plateReducer.statusDetail as string;
};

export const selectSelectedPlate = (state: any) => {
  return state.plateReducer.selectedPlate as PlateDetail;
};

export const selectedCategory = (state: any) => {
  const cat = state.plateReducer.selectedCategory as Category;
  if (cat) {
    return cat;
  }
  const categories = state.plateReducer.categories;
  if (categories) {
    return categories[0] as Category;
  }
  return cat;
};

const plateReducer = plateSlice.reducer;
export default plateReducer;

export const savePlate = async (plate: PlateDetail) => {
  return fetch(`${process.env.REACT_APP_URL_SERVER}/plates`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(plate),
  });
};
