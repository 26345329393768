import { Button, Dialog, Grid } from "antd-mobile";
import { useEffect, useState } from "react";
import DrinkCommand from "../drink/drink-command";
import { PlatItem, PlateDetail } from "../../models/plate";
import { useNavigate, useParams } from "react-router-dom";
import { GridItem } from "antd-mobile/es/components/grid/grid";
import { connect } from "react-redux";
import { getAllPlates } from "../../actions/configuration";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Category, fetchPlates, filteredPlate, selectAllPlates, selectedCategory } from "../../service/configuration";
import { store } from "../../redux/store";
import "./pad.css";
import { selectedPlate } from "../../service/plate-detail";
import { DownOutline, UpOutline } from "antd-mobile-icons";
import { categories } from "../../service/configuration";
import logging from '../../helper/LoggingHelper';
import { text } from 'stream/consumers';

const MAX_ITEMS_BY_PAGE = 12;

type PadProps = {
  filter?: string;
  addItem: (item: PlatItem) => void;
  sendCommand: () => void;
  addNewGroup?: () => void;
  setCurrentGroup?: ()=>void
};

type FoodCriteria = {
  category: string;
  subCategory?: string;
  favorite?: boolean;
};

export type AppDispatch = typeof store.dispatch;

const Pad = ({ addItem, sendCommand, addNewGroup }: PadProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const plates = useSelector(selectAllPlates);
  const currentCategory = useSelector(selectedCategory);

  let { serviceId } = useParams<string>();
  let { commandId } = useParams<string>();


  let [category, setCategory] = useState<Category>(categories[0]);
  let [subCategorie, setSubCategorie] = useState<String>();
  let [displayPlates, setDisplayPlates] = useState<PlateDetail[]>();
  let [pageIndex, setPageIndex] = useState<number>(0);
  let [hasNext, setHasNext] = useState<boolean>(false);
  let [hasPrevious, setHasPrevious] = useState<boolean>(false);

  let currentGroup = 'Direct';

  const plateStatus = useSelector((state: any) => {
    return state.plateReducer.status;
  });

  //let [items, setItems] = useState(data);
  const navigate = useNavigate();

  const removeSpace = (name?: string) => {
    return name ? name.toLowerCase().replaceAll(' ', '-') : '';
  };

  const handle = (item: PlateDetail) => {
    if (item.category === 'boisson' && item.quantities && (item.topics === undefined || item.topics.length === 0) ) {
      let platItem = {
        quantity: 0,
        item: item,
      };
      Dialog.confirm({
        content: <DrinkCommand item={item} onQuantitychange={(quantity) => (platItem.quantity = quantity)} />,
        cancelText: 'Annuler',
        confirmText: 'Ajouter',
        onConfirm: () => {
          if (addItem !== undefined) {
            const theItem = {
              originalPlate: item,
              description: platItem.quantity + ' ' + item.unit,
            };

            addItem(theItem);
          }
        },
      });
    } else  {
      if (item.hasTopics || item.hasSauce || item.hasCuissonMode || item.hasIceScream || (item.topics && item.topics.length>0)) {
        dispatch(selectedPlate(item));
        navigate('plate');
      } else {
        const theItem = {
          originalPlate: item,
        };
        addItem(theItem);
      }
    }
  };

  useEffect(() => {
    if (plateStatus === 'idle') {
      dispatch(fetchPlates());
      setPageIndex(0);
    }
  }, [dispatch, plateStatus]);

  useEffect(() => {
    if (plateStatus === 'succeeded') {
      setPageIndex(0);
      const query = [category, subCategorie];
      dispatch(filteredPlate(query));
    }
  }, [dispatch, category, subCategorie, plateStatus]);

  const changeCategory = (cat: Category) => {
    setCategory(cat);
    setSubCategorie('');
    setPageIndex(0);
  };

  const processCommand = () => {
    navigate('/service');
  };

  const changeSubCategory = (cat: Category, sub: string) => {
    setCategory(cat);
    setSubCategorie(sub);
    setPageIndex(0);
  };

  const findSubCategory = (s:string) => {
    const ret = category.subCategories.filter(sub => sub.name === s)[0];
    if(ret === undefined){
      logging.error(`Sub ${s} non trouve / categorie actuelle : ${category.category}`);
    }
    return ret;
  }

  useEffect(() => {
    let _plates = [...plates];

    const stack = _plates
      .filter((d) => d.state === undefined || d.state === 'ENABLE')
      .filter(d => d.category !== 'topic')
      .reduce((acc, current) => {
        let l = acc.get(current.subCategory!);
        if (l === undefined) {
          l = [] as PlateDetail[];
          acc.set(current.subCategory!, l);
        }
        l.push(current);
        return acc;
      }, new Map<string, PlateDetail[]>());

    _plates = Array.from(stack.keys())
      .map((s) => findSubCategory(s))
      .sort((c1, c2) => c1.order - c2.order)
      .filter(cat =>  cat!==undefined)
      .flatMap(cat => stack.get(cat.name)!.sort((s1, s2) => s1.name.localeCompare(s2.name)));

    //setDisplayPlates(_plates.slice(pageIndex * MAX_ITEMS_BY_PAGE, MAX_ITEMS_BY_PAGE * (pageIndex + 1)));
    setDisplayPlates(_plates);
    setHasPrevious(pageIndex > 0);
    setHasNext((pageIndex + 1) * MAX_ITEMS_BY_PAGE + 1 < plates.length);
  }, [plates, pageIndex]);

  return (
    <div className={'pad'}>
      <Grid columns={5}>
        {categories
          ?.filter((it) => it.mainPlate)
          .sort((a, b) => a.order - b.order)
          .map((c) => {
            return (
              <Button className={removeSpace(c.category)} key={c.category} onClick={() => changeCategory(c)}>
                {c.category}
              </Button>
            );
          })}
      </Grid>
      <Grid columns={3}>
        <GridItem span={2} style={{ overflow: 'auto', height: '320px' }}>
          <Grid columns={3}>
            {displayPlates
              ?.filter((d) => d.favorite ?? false)
              .map((d) => (
                <Button key={d.id} style={{}} className={'plate ' + d.category + ' ' + removeSpace(d.subCategory)} onClick={() => handle(d)}>
                  <span>{d.name}</span>
                  <span>{d.prize}</span>
                </Button>
              ))}

            {displayPlates
              ?.filter((d) => !(d.favorite ?? false))
              .map((d) => (
                <Button key={d.id} style={{}} className={'plate ' + d.category + ' ' + removeSpace(d.subCategory)} onClick={() => handle(d)}>
                  <span>{d.name}</span>
                  <p style={{position:"absolute", right: "2px", bottom: "2px", padding:"0px", margin:"0px"}}>{d.prize} &euro;</p>
                </Button>
              ))}
          </Grid>
        </GridItem>
        <GridItem>
          <Grid columns={2}>
            <Button className={'send'} key='Envoyer' onClick={() => sendCommand()}>
              Envoyer
            </Button>
            <Button className={'suite'} key='Suite' onClick={() => addNewGroup!()}>
              + Suite
            </Button>

            {currentCategory &&
              Array.from(currentCategory?.subCategories)
                .sort((s1, s2) => s1.order - s2.order)
                .map((c) => {
                  return (
                    <Button
                      className={'sub-category ' + currentCategory.category + ' ' + removeSpace(c.name)}
                      onClick={() => changeSubCategory(currentCategory, c.name)}
                      key={c.name}
                    >
                      {c.name}
                    </Button>
                  );
                })}
          </Grid>
        </GridItem>
      </Grid>
    </div>
  );
};

export { Pad };
export default connect(null, { getAllPlates })(Pad);
