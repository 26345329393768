import { CommandItem } from '../models/plate';

const getAllGroups = (data: CommandItem[]) => {
  return Array.from(data.map((it) => it.group || 'Direct').reduce((stack, current) => stack.add(current), new Set<String>()));
};


const getLastGroup =  (data?: CommandItem[]) => {
    if(data){
        const groups =  getAllGroups(data).sort().reverse();
        return groups.length === 0 ? "Direct":groups[0];
    }
    return "Direct";
}

export { getAllGroups, getLastGroup };
