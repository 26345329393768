import { TabBar } from "antd-mobile";
import Header from "../components/header/header";
import { CardItem, MenuItems } from "../components/menu/card-item";
import { useNavigate } from "react-router-dom";
import { AppOutline } from "antd-mobile-icons";
import { ScrollContent } from '../components/misc/scrollcontent';
import { CMenu } from '../components/menu/menu';

const tabs = [
  {
    key: "home",
    title: "Home",
    icon: <AppOutline />,
  },
];

const ParamWelcome = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Header title='Configuration' hasPrevious>
        <CMenu />
      </Header>
      <ScrollContent compoenentsHeight={90}>
        <div className='body'>
          <MenuItems>
            <CardItem title='Topics' goTo='/params/topic' fontIconName='gear' />
            <CardItem title='Boisson' goTo='/params/boisson' fontIconName='martini-glass-citrus' />
            <CardItem title='Entree' goTo='/params/entree' fontIconName='plate-wheat' />
            <CardItem title='Plat' goTo='/params/plat' fontIconName='plate-wheat' />
            <CardItem title='Dessert' goTo='/params/dessert' fontIconName='ice-cream' />
          </MenuItems>
        </div>
      </ScrollContent>
      <div className='bottom'>
        <TabBar activeKey={null} onChange={(item) => navigate('/')}>
          {tabs.map((item) => (
            <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
          ))}
        </TabBar>
      </div>
    </div>
  );
};

export default ParamWelcome;
