import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card } from "antd-mobile";
import { RightOutline } from "antd-mobile-icons";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import "./card-item.css";

export type CardItemProps = {
  goTo: string;
  title: string;
  fontIconName?: IconProp;
};

export type MenuItemsProps = {
  children: ReactNode[];
};

const CardItem = ({ goTo, title, fontIconName }: CardItemProps) => {
  const navigate = useNavigate();
  return (
    <Card
      className="menu-item"
      title={
        <div style={{ fontWeight: "normal" }}>
          {fontIconName && <FontAwesomeIcon icon={fontIconName} size="2x" style={{ marginRight: "20px" }} color="#772d2c"></FontAwesomeIcon>}
          {title}
        </div>
      }
      style={{ borderRadius: "16px" }}
      extra={<RightOutline />}
      onClick={() => navigate(goTo)}
    ></Card>
  );
};

const MenuItems = (props: MenuItemsProps) => {
  return <div className="menu">{props.children}</div>;
};
export { CardItem, MenuItems };
