import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Command } from "../models/plate";
import { ServiceModel } from "../models/plate";
import cacheService from "./storage";

export type CommmandsState = {
  status: string;
  statusSave: string;
  commands: Command[];
  command?: Command;
};

export type ServiceEnableState = {
  status: string;
  service?: ServiceModel;
};

export type ServiceCompositionState = {
  commands: Command[];
};

export type PushCommandQuery = {
  command: Command;
  processSend: boolean;
};

const initialCommandState: CommmandsState = {
  status: "idle",
  statusSave: "",
  commands: [],
};

const initialState: ServiceCompositionState = {
  commands: [],
};

export const fetchServices = createAsyncThunk("services/fetchService", async () => {
  try {
    var query = await fetch(`${process.env.REACT_APP_URL_SERVER}/services`);
    var ret = await query.json();
    return ret as ServiceModel[];
  } catch (e) {
    throw e;
  }
});

export const fetchServiceById = createAsyncThunk('services/fetchServiceById', async (serviceId:string) => {
  try {
    var query = await fetch(`${process.env.REACT_APP_URL_SERVER}/services/${serviceId}`);
    var ret = await query.json();
    return ret as ServiceModel;
  } catch (e) {
    throw e;
  }
});

export const fetchEnableService = createAsyncThunk("services/fetchEnableService", async () => {
  try {
    var query = await fetch(`${process.env.REACT_APP_URL_SERVER}/services?state=ENABLE`);
    var ret = (await query.json()) as ServiceModel[];
    return ret.length == 0 ? undefined : ret[0];
  } catch (e) {
    throw e;
  }
});

export const fetchCommands = createAsyncThunk("services/fetchCommands", async (service_id: string) => {
  try {
    var query = await fetch(`${process.env.REACT_APP_URL_SERVER}/services/${service_id}/commands`);
    var ret = await query.json();
    return ret as Command[];
  } catch (e) {
    throw e;
  }
});

export const fetchAllServices = createAsyncThunk("services/fetchAllServices", async () => {
  try {
    var query = await fetch(`${process.env.REACT_APP_URL_SERVER}/services`);
    var ret = await query.json();
    return ret as ServiceModel[];
  } catch (e) {
    throw e;
  }
});



export const saveCommand = createAsyncThunk("services/saveCommand", async (command: Command) => {
  try {
    var query = await fetch(`${process.env.REACT_APP_URL_SERVER}/services/${command.serviceId!}/commands`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(command),
    });
    if(query.status === 200 || query.status === 201){
      return command as Command ;
    }
  } catch (e) {
    throw e;
  }
   throw new Error(`status ${query.status}`);
});


export type ServiceSliceState = {
  status: string;
  service?: ServiceModel;
  statusService: string;
  services?: ServiceModel[];
}

export const serviceSlice = createSlice({
  name: "services",
  initialState: {
    status: "idle",
    services: [] as ServiceModel[],
    statusService: "idle",
    service: undefined
  } as ServiceSliceState,
  reducers: {
    reset: (state) => {
      state.status = "idle";
      state.statusService =  "idle";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAllServices.pending, (state, action) => {
        state.status = "loading";
        state.services = [];
      })
      .addCase(fetchAllServices.fulfilled, (state, action) => {
        state.status = "sucess";
        state.services = action.payload;
      })
      .addCase(fetchAllServices.rejected, (state, action) => {
        state.status = "failed";
        state.services = [];
      })
       .addCase(fetchServiceById.pending, (state, action) => {
        state.statusService = "loading";
        state.service = undefined;
      })
      .addCase(fetchServiceById.fulfilled, (state, action) => {
        state.statusService = "sucess";
        state.service = action.payload;
      })
      .addCase(fetchServiceById.rejected, (state, action) => {
        state.statusService = 'failed';
        state.service = undefined;
      });
  },
});

export const serviceEnableSlice = createSlice({
  name: "serviceEnable",
  initialState: {
    status: "idle",
  } as ServiceEnableState,
  reducers: {
    resetEnableService: (state) => {
      state.status = "idle";
      state.service = undefined;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchEnableService.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchEnableService.fulfilled, (state, action) => {
        state.status = "sucess";
        state.service = action.payload;
      })
      .addCase(fetchEnableService.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export const commandSlice = createSlice({
  name: "commands",
  initialState: initialCommandState,
  reducers: {
    resetComamnds: (state) => {
      state.status = "idle";
      state.statusSave = "";
      state.command = undefined;
    },
    pushCommand: (state, { type, payload }) => {
      const currentCommand: Command = Object.assign({}, payload.command);
      if (payload.processSend === true) {
        const oldItems = currentCommand.items.filter((it) => it.status !== "NEW");
        const newCommandItems = currentCommand.items.filter((it) => it.status === "NEW").map((it) => Object.assign({}, it));
        newCommandItems.forEach((it) => (it.status = "SENDING"));
        currentCommand.items = [...oldItems, ...newCommandItems];
        state.commands = [...state.commands.filter((it) => it.id !== currentCommand.id), currentCommand];
      } else {
        state.commands = [...state.commands.filter((it) => it.id !== currentCommand.id), currentCommand];
      }

      cacheService.putItem(`COMMAND_${currentCommand.id}`, currentCommand);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCommands.pending, (state, action) => {
        state.status = 'loading';
        state.commands = [];
      })
      .addCase(fetchCommands.fulfilled, (state, action) => {
        state.status = 'sucess';
        state.commands = action.payload;
      })
      .addCase(fetchCommands.rejected, (state, action) => {
        state.status = 'failed';
        state.commands = [];
      })
      .addCase(saveCommand.pending, (state, action) => {
         state.statusSave = 'failed';
      })
      .addCase(saveCommand.fulfilled, (state, action) => {
         state.statusSave = 'success';
         state.command = action.payload;
      })
      .addCase(saveCommand.rejected, (state, action) => {
         state.statusSave = 'failed';
      });
  },
});

/**
 * Permet de requeter pour obtenir des informations sur une commande
 */
export type QueryCommand = {
  serviceId: string;
  commandId: string;
};

export const fetchSingleCommand = createAsyncThunk('services/fetchSingleCommand', async (params: QueryCommand) => {
  try {
    var query = await fetch(`${process.env.REACT_APP_URL_SERVER}/services/${params.serviceId!}/commands/${params.commandId}`);
    var ret = await query.json();
    return ret as Command;
  } catch (e) {
    throw e;
  }
});

export const queryCommandSlice = createSlice({
  name: 'queryCommand',
  initialState: initialCommandState,
  reducers: {
    resetSelectedCommand: (state) => {
      state.status = 'idle';
      state.command = undefined;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSingleCommand.pending, (state, action) => {
        state.status = 'loading';
        state.command = undefined;
      })
      .addCase(fetchSingleCommand.fulfilled, (state, action) => {
        state.status = 'success';
        state.command = action.payload;
      })
      .addCase(fetchSingleCommand.rejected, (state, action) => {
        state.status = 'failed';
        state.command = undefined;
      })
  },
});

export const selectQueryCommandStatus= (state: any): string => {
  return state.qeuryCommandReducer.status;
};

export const selectQueryCommand = (state: any): Command => {
  return state.qeuryCommandReducer.command;
};

// Find slice queryCommandSlice

export const { pushCommand } = commandSlice.actions;

export const selectCommands = (state: any): Command[] => {
  return state.commandReducer.commands;
};

export const selectCommandstatus = (state: any): string => {
  return state.commandReducer.status;
};

export const selectCommandStatusSave = (state: any): string => {
  return state.commandReducer.statusSave;
};

export const selectServiceStatus = (state: any): string => {
  return state.serviceReducer.status;
};

export const selectServices = (state: any): ServiceModel[] => {
  return state.serviceReducer.services;
};

export const selectServiceEnableStatus = (state: any): string => {
  return state.serviceEnableReducer.status;
};

export const selectServiceEnable = (state: any): ServiceModel => {
  return state.serviceEnableReducer.service;
};

export const selectService = (state: any): ServiceModel => {
  return state.serviceReducer.service;
};

export const selectStatusService = (state: any): string => {
  return state.serviceReducer.statusService;
};


export const selectSaveCommand = (state: any): Command => {
  return state.commandReducer.command;
};

export const { resetComamnds } = commandSlice.actions;
export const { reset } = serviceSlice.actions;
export const { resetEnableService } = serviceEnableSlice.actions;
export const { resetSelectedCommand } = queryCommandSlice.actions;

const serviceReducer = serviceSlice.reducer;
const commandReducer = commandSlice.reducer;
const serviceEnableReducer = serviceEnableSlice.reducer;
const qeuryCommandReducer = queryCommandSlice.reducer;

export { serviceReducer, commandReducer, serviceEnableReducer, qeuryCommandReducer };
