import { useSelector } from "react-redux";
import Header from "../components/header/header";
import {
  PushCommandQuery,
  fetchCommands,
  pushCommand,
  reset,
  resetComamnds,
  saveCommand,
  selectCommands,
  selectCommandstatus,
} from "../service/service";
import { AppDispatch } from "../redux/store";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button, Card, Grid } from "antd-mobile";
import { Command, CommandItem } from "../models/plate";

const CuisinePage = () => {
  const commands = useSelector(selectCommands);
  const dispatch = useDispatch<AppDispatch>();
  const commandsStatus = useSelector(selectCommandstatus);
  let { serviceId } = useParams<string>();

  let [gridColumns, setGridColumns] = useState<number>(1);
  let [flexDirection, setFlexDirection] = useState<any>("column");

  let [displayCommands, setDisplayCommands] = useState<Command[]>();

  const handleResize = () => {
    if (window.innerWidth < window.innerHeight) {
      setGridColumns(1);
      setFlexDirection("column");
    } else {
      setGridColumns(commands != undefined ? commands.length : 1);
      setFlexDirection("row");
    }
  };

  useEffect(() => {
    dispatch(fetchCommands(serviceId!));
    handleResize();
    window.addEventListener("resize", handleResize);
  }, [serviceId]);

  useEffect(() => {
    if (commandsStatus === "idle") {
      dispatch(fetchCommands(serviceId!));
    } else {
      handleResize();
      setDisplayCommands(commands.filter((c) => c.items.filter((it) => filterPlate(it, "SENDING", ["boisson"])).length !== 0));
    }
  }, [commandsStatus, serviceId]);

  const filterPlate = (it: CommandItem, status: any, categries: string[]) => {
    return categries.indexOf(it.originalPlate?.category!) >= 0 && it.status === status;
  };

  const pushCommandAndSend = async (comm: Command) => {
    let newCommand = Object.assign({}, comm);
    const oldItems = newCommand.items.filter((it) => !filterPlate(it, "SENDING", ["boisson"]));
    const newCommandItems = newCommand.items.filter((it) => filterPlate(it, "SENDING", ["boisson"])).map((it) => Object.assign({}, it));
    newCommandItems.forEach((it) => (it.status = "TERMINATED"));
    newCommand.items = [...oldItems, ...newCommandItems];

    dispatch(saveCommand(newCommand));
    //faire mieux !!
    setTimeout(() => dispatch(resetComamnds()), 2000);
  };

  return (
    <div className="service">
      <Header title="Bar" hasPrevious redirectUrl={"/service/" + serviceId} />
      <section>
        <div style={{ display: "flex", flexDirection: flexDirection, overflow: "auto", maxWidth: window.innerWidth }}>
          {displayCommands &&
            displayCommands.map((comm) => (
              <Card style={{ margin: 5, minWidth: "300px" }}>
                <div style={{ margin: "5px 10px 10px 6px" }}>
                  <div style={{ fontWeight: "bold", fontSize: "18px", marginBottom: "10px" }}>
                    <span style={{ borderBottom: "2px solid red" }}>{"Table " + comm.tableName + "(" + comm.nbPersons + ")"}</span>
                  </div>
                  {comm.items
                    .filter((it) => it.originalPlate?.category === "boisson")
                    .map((it) => (
                      <>
                        <div style={{ fontSize: "20px" }}>
                          <span style={{ fontWeight: "bold" }}>{it.quantity}</span>
                          {" x " + it.originalPlate?.name}
                        </div>
                        <ul style={{ fontSize: "15px" }}>{it.topics && it.topics.map((topic) => <li>{topic.name}</li>)}</ul>
                      </>
                    ))}
                  <div style={{ textAlign: "center" }}>
                    <Button color="success" onClick={() => pushCommandAndSend(comm)} style={{ width: "90%" }}>
                      Terminer
                    </Button>
                  </div>
                </div>
              </Card>
            ))}
        </div>
      </section>
    </div>
  );
};

export default CuisinePage;
