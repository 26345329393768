import { useDispatch } from "react-redux";
import { AppDispatch } from "../redux/store";
import "./service.css";
import {  Card, FloatingBubble,  TabBar } from "antd-mobile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AddCircleOutline, AppOutline } from "antd-mobile-icons";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { fetchCommands, reset, resetEnableService, selectCommands, selectCommandstatus } from "../service/service";
import { Command } from "../models/plate";
import { editCommand } from "../service/client";
import Header from "../components/header/header";
import { useEffect } from "react";
import ServiceList from "../components/service/service-list";
import { v4 as uuidv4 } from "uuid";
import { ScrollContent } from '../components/misc/scrollcontent';

const tabs = [
  {
    key: "home",
    title: "Home",
    icon: <AppOutline />,
  },
];

type DisplayCommandProp = {
  item: Command;
  selectCommand: (item: Command) => void;
};

type DisplayCommandAddProp = {};

const DisplayCommand = ({ item, selectCommand }: DisplayCommandProp) => {
  return (
    <Card className="table-info" key={item.id} onClick={() => selectCommand(item)}>
      <div>
        <FontAwesomeIcon icon="plate-wheat" size="2x"></FontAwesomeIcon>
        <div>
          {item.tableName} ({item.nbPersons})
        </div>
      </div>
    </Card>
  );
};

const DisplayAdd = () => {
  const navigate = useNavigate();
  return (
    <Card className="table-add" key="add-command" style={{ backgroundColor: "#25a244" }} onClick={() => navigate("/nouvelle-table")}>
      <div>
        <FontAwesomeIcon icon="plus-circle" size="2x" color="white"></FontAwesomeIcon>
        <div className="description">Nouvelle table</div>
      </div>
    </Card>
  );
};

const ServiceListPage = () => {
  const navigate = useNavigate();
  const commands = useSelector(selectCommands);
  const dispatch = useDispatch<AppDispatch>();
  const commandsStatus = useSelector(selectCommandstatus);

  useEffect(() => {
    if (commandsStatus == "idle") {
      dispatch(fetchCommands("1"));
    }
  }, [commandsStatus]);

  const selectCommand = (command: Command) => {
    dispatch(editCommand(command));
    navigate("/commande");
  };

  const getSortedCommands = (status: any) => {
    const comms = [...commands].filter((it) => it.status === status);

    comms.sort((a: Command, b: Command) => a.id.localeCompare(b.id));
    return comms;
  };

  const createService = async () => {
    const dt = new Date().toISOString().slice(0, 10);
    const service = {
      id: uuidv4(),
      createdDate: dt,
      state: "ENABLE",
    };

    const ret = await fetch(`${process.env.REACT_APP_URL_SERVER}/services`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(service),
    });
    if (ret.status == 200) {
      dispatch(reset());
      dispatch(resetEnableService());
    }
  };
  return (
    <div className='service'>
      <Header title='Les Services' hasPrevious displayMenu />
      <div className='body'>
        <ScrollContent compoenentsHeight={90}>
          <ServiceList />
          <FloatingBubble
            axis='xy'
            magnetic='x'
            style={{
              '--initial-position-bottom': '24px',
              '--initial-position-right': '24px',
              '--edge-distance': '24px',
            }}
          >
            <AddCircleOutline fontSize={32} onClick={() => createService()} />
          </FloatingBubble>
        </ScrollContent>
      </div>
      <div className='bottom'>
        <TabBar activeKey={null} onChange={(item) => navigate('/')}>
          {tabs.map((item) => (
            <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
          ))}
        </TabBar>
      </div>
    </div>
  );
};

export default ServiceListPage;
