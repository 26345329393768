import { PlateDetail } from "../../models/plate";
import { AppOutline } from "antd-mobile-icons";
import ListPlateDetail from "../../components/configuration/cell-item";

const ConfigDessert = () => {
  return (
    <div>
      <ListPlateDetail category="dessert" />
    </div>
  );
};

export default ConfigDessert;
