import Header from "../components/header/header";
import { CardItem, MenuItems } from "../components/menu/card-item";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../redux/store";
import { useEffect } from "react";
import { fetchEnableService, selectServiceEnable, selectServiceEnableStatus } from "../service/service";
import { useSelector } from "react-redux";
import { ScrollContent } from '../components/misc/scrollcontent';

const WelcomePage = () => {
  const dispach = useDispatch<AppDispatch>();

  let status = useSelector(selectServiceEnableStatus);
  let service = useSelector(selectServiceEnable);

  useEffect(() => {
    if (status === "idle") {
      dispach(fetchEnableService());
    }
  });

  useEffect(() => {}, [status, service]);

  return (
    <div className='accueil'>
      <Header title='Accueil' hasPrevious={false}></Header>
      <ScrollContent compoenentsHeight={40}>
        <div>
          <div className='intro'>
            <img src='catsberg.gif' />
          </div>
          <div className='body'>
            <MenuItems>
              {service && <CardItem title='Service actif' goTo={'/service/' + service.id} fontIconName='plate-wheat' />}
              <CardItem title='Gestion des Services' goTo='/service-list' fontIconName='plate-wheat' />
              <CardItem title='Paramétrage' goTo='/params' fontIconName='gear' />
              <CardItem title='Accés' goTo='/params' fontIconName='users' />
            </MenuItems>
          </div>
        </div>
      </ScrollContent>
    </div>
  );
};

export default WelcomePage;
