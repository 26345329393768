import ListPlateDetail from "../../components/configuration/cell-item";

const ConfigPlate = () => {
  return (
    <div>
      <ListPlateDetail category="plat" />
    </div>
  );
};

export default ConfigPlate;
