import { useDispatch } from "react-redux";
import { AppDispatch } from "../pad/pad";
import { useEffect, useState } from "react";
import { fetchAllServices, reset, resetEnableService, selectServiceStatus, selectServices } from "../../service/service";
import { useSelector } from "react-redux";
import { Button, Card, Dialog, Grid, List } from "antd-mobile";
import { RightOutline } from "antd-mobile-icons";
import "./service-list.css";
import { ServiceModel } from "../../models/plate";
import { useNavigate } from 'react-router-dom';

const ServiceList = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  let status = useSelector(selectServiceStatus);
  let services = useSelector(selectServices);

  useEffect(() => {
    if (status == "idle") {
      dispatch(fetchAllServices());
    }
  }, [status]);

  useEffect(() => {}, [status, services]);

  const formatDate = (dt: string) => {
    const d = new Date(Date.parse(dt));
    const options = { weekday: "long", year: "numeric", month: "long", day: "numeric" } as Intl.DateTimeFormatOptions;
    return d.toLocaleDateString("fr-FR", options);
  };

  const getClassName = (state?: string) => {
    return isEnable(state) ? "enable" : "disable";
  };

  const isEnable = (state?: string) => {
    return state === "ENABLE";
  };

  const terminateService = (service: ServiceModel) => {
    Dialog.confirm({
      content: <div>Souhaitez vous terminer ce service ?</div>,
      cancelText: "Annuler",
      confirmText: "Terminer",
      onConfirm: async () => {
        const ret = await fetch(`${process.env.REACT_APP_URL_SERVER}/services/${service.id}/terminate`, {
          method: "POST",
        });
        if (ret.status == 200) {
          dispatch(reset());
          dispatch(resetEnableService());
        }
      },
    });
  };

  const getSortedServices = (services:ServiceModel[]) =>{
    let _services = [...services];
    return _services.sort((e1, e2) => e2.createdDate.localeCompare(e1.createdDate));
  }

  const goToServiceDetail = (service:ServiceModel) => {
    navigate(`/service/${service.id}`);
  }

  return (
    <>
      {status == 'Loading' && <div>Chargement...</div>}
      {status == 'sucess' && services.length > 0 && (
        <div>
          <Grid columns={1} gap={6} className='service-list'>
            {getSortedServices(services).map((it) => (
              <Card
                key={it.id}
                onHeaderClick={() => goToServiceDetail(it)}
                title={'Service du ' + formatDate(it.createdDate)}
                style={{ borderRadius: '16px' }}
                extra={<RightOutline />}
                className={'service-detail ' + getClassName(it.state)}
              >
                <div className='card-body' onClick={(e) => e.stopPropagation()}>
                  {it.details && (
                    <>
                      <div>Nb. Tables : {it.details.nbTables}</div>
                      <div>Nb. Couverts : {it.details.nbPersons}</div>
                    </>
                  )}

                  {isEnable(it.state) && (
                    <Button color='primary' onClick={() => terminateService(it)}>
                      Terminer
                    </Button>
                  )}
                </div>
              </Card>
            ))}
          </Grid>
        </div>
      )}
      {status == 'sucess' && services.length == 0 && <div>Aucun service disponible</div>}
    </>
  );
};

export default ServiceList;
