import { Button, Form, Grid, Input, Selector, Stepper } from "antd-mobile";
import Header from "../components/header/header";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../redux/store";
import { pushCommand, resetComamnds, saveCommand, selectCommandStatusSave, selectSaveCommand } from "../service/service";
import { v4 as uuidv4 } from "uuid";
import { Command } from "../models/plate";
import { editCommand } from "../service/client";
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import logging from '../helper/LoggingHelper';

export const options = [
  {
    label: "Bar",
    value: "1",
  },
  {
    label: "Etage",
    value: "2",
  },
  {
    label: "Terrasse",
    value: "3",
  },
  {
    label: "Terrasse Park",
    value: "4",
  },
];

type TableType = { tableName?: string; nbPersons?: number; place?: number };

const NouvelleTablePage = () => {
  const navigate = useNavigate();
  let { serviceId } = useParams<string>();
  const dispatch = useDispatch<AppDispatch>();

  const [form] = Form.useForm<TableType>();
  const tableName = Form.useWatch("tableName", form);
  const nbPersons = Form.useWatch("nbPersons", form);
  const place = Form.useWatch("place", form);

  const theCommand = useSelector(selectSaveCommand);

  let statusSave = useSelector(selectCommandStatusSave);
  useEffect(() =>{
    if(statusSave === "success"){
      logging.debug("Initialisation de la commande à editer.")
      dispatch(pushCommand({ command: theCommand }));
      dispatch(resetComamnds());
      dispatch(editCommand(theCommand));
      navigate(`/service/${serviceId}/commande/${theCommand.id}`);
    }
  }, [statusSave])

  const validate = async (value: any) => {
    const command = {
      tableName: value.tableName,
      id: uuidv4(),
      nbPersons: value.nbPersons,
      items: [],
      serviceId: serviceId,
      status: "IN_PROGRESS",
    } as Command;
    dispatch(saveCommand(command));
  };
  return (
    <div>
      <Header title="Nouvelle table" hasPrevious></Header>
      <Form
        layout="vertical"
        initialValues={{ nbPersons: 4 }}
        onFinish={validate}
        footer={
          <>
            <div
              style={{
                marginBottom: "24px",
                fontSize: "15px",
                color: "var(--adm-color-weak)",
              }}
            ></div>
            <Grid columns={2} gap={5}>
              <Button block color="danger" size="large" onClick={() => navigate(`/service/${serviceId}`)}>
                Annuler
              </Button>
              <Button block type="submit" color="success" size="large">
                Enregitrer
              </Button>
            </Grid>
          </>
        }
      >
        <Form.Item name="tableName" label="Numero de table" rules={[{ required: true, message: "Numero de table requis" }]}>
          <Input onChange={console.log} placeholder="101" />
        </Form.Item>
        <Form.Item name="nbPersons" label="Nombre de personnes" rules={[{ required: true, message: "Nombre de personnes requis" }]}>
          <Stepper step={1} min={1} max={50} />
        </Form.Item>
        <Form.Item name="place" label="Lieu" rules={[{ required: true, message: "Indiquez le lieu" }]}>
          <Selector options={options} onChange={(arr, extend) => console.log(arr, extend.items)} columns={2} />
        </Form.Item>
      </Form>
    </div>
  );
};

export default NouvelleTablePage;
