import { useDispatch } from "react-redux";
import Header from "../../components/header/header";
import { AppDispatch } from "../../redux/store";
import { useEffect, useState } from "react";
import { fetchPlates, selectPlateStatus, selectPlates } from "../../service/configuration";
import { useSelector } from "react-redux";
import { PlateDetail } from "../../models/plate";
import { AppOutline } from "antd-mobile-icons";
import { useNavigate } from "react-router-dom";
import CategoryDetail from "../../components/configuration/cell-item";
import ListPlateDetail from "../../components/configuration/cell-item";

const tabs = [
  {
    key: "home",
    title: "Home",
    icon: <AppOutline />,
  },
];

type DrinkByCategory = {
  subCategory: string;
  drinks: PlateDetail[];
};
const ConfigDrink = () => {
  return (
    <div>
      <ListPlateDetail category="boisson" />
    </div>
  );
};

export default ConfigDrink;
