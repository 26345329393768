import { LeftOutline } from "antd-mobile-icons";
import "./header.css";
import { useNavigate } from "react-router-dom";
import { ComponentType } from 'react';
import { CMenu } from '../menu/menu';

type HeaderProp = {
  title?: string;
  redirectUrl?: string;
  hasPrevious: boolean;
  redirectAction?: () => void;
  children?: string | JSX.Element | JSX.Element[];
  displayMenu?: boolean
};
const Header = ({ title, redirectUrl, hasPrevious, redirectAction, children, displayMenu }: HeaderProp) => {
  const navigate = useNavigate();
  const goBack = () => {
    if (redirectAction) {
      redirectAction();
    } else {
      navigate(redirectUrl ? redirectUrl : '../');
    }
  };
  return (
    <header>
      <div className='title'>
        {hasPrevious && <LeftOutline className='back' onClick={goBack} />}
        {!hasPrevious && <div style={{"width": "1em", "height": "1em"}}>&nbsp;</div>}
        {title && <div style={{ flex: 'auto' }}>{title}</div>}
        {children && children}
        {displayMenu && <CMenu />}
      </div>
    </header>
  );
};

export default Header;
