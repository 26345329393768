import { useEffect, useState } from "react";
import { Command, CommandItem, PlatItem } from "../models/plate";
import { Grid, TabBar } from "antd-mobile";
import { Pad } from "../components/pad/pad";
import { Client } from "../components/client/client";
import { GridItem } from "antd-mobile/es/components/grid/grid";
import { AppDispatch } from "../redux/store";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { addPlate, changeQuantity, editCommand, selectEditCommand, selectItems, selectTableInfo, setCurrentGroup } from "../service/client";
import { useNavigate, useParams } from "react-router-dom";
import { PushCommandQuery, fetchSingleCommand, pushCommand, saveCommand, selectCommandStatusSave, selectCommands, selectQueryCommand, selectQueryCommandStatus } from "../service/service";
import Header from "../components/header/header";
import {getAllGroups, getLastGroup} from '../helper/GroupHelper';
import { selectedPlate } from '../service/plate-detail';
import { AppOutline } from 'antd-mobile-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import getTotal from '../helper/CommandHelper';
import logging from '../helper/LoggingHelper';
import { PrintModel, print } from '../service/printer';
import { v4 as uuidv4 } from 'uuid';
import { categories } from '../service/configuration';

const HEADER_HEIGHT = 40;
const FOOTER_HEIGHT = 50;
const PAD_HEIGHT = 40 + 80 * 4 + 0 * 40;


const DEFAULT_TABS = [
  {
    key: 'home',
    title: 'Home',
    icon: <AppOutline />,
  },
  {
    key: 'facture',
    title: 'facture',
    icon: <FontAwesomeIcon icon={'file-invoice'} />,
  },
];

const CommandePage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  
  let { serviceId } = useParams<string>();
  let { commandId } = useParams<string>();

  let [height, setHeight] = useState<number>(200);
  let [tabs, setTabs] = useState(DEFAULT_TABS);
  let [padVisible, setPadVisible] = useState(true);
  const commandSaveStatus = useSelector(selectCommandStatusSave);
  const commandQueryStatus = useSelector(selectQueryCommandStatus);
  //const commandQuery = useSelector(selectQueryCommand);


  const [gridColumns, setGridColumns] = useState(window.innerWidth < window.innerHeight ? 1 : 2);

  const comm = useSelector(selectEditCommand);
  const selectedComm = useSelector(selectQueryCommand);


  const items = useSelector(selectItems);
  const clientInfo = useSelector(selectTableInfo);
  const cat = categories;

  const findCategory = (name:string)=>{
    return cat.filter(c => name===c.category)[0];
  }

  /**
   * Effect associé à la detection du changement des paramétres
   */
  useEffect(() => {
    logging.debug(`Service ${serviceId}, Commande : ${commandId}, Comm : ${comm && comm.id}, SelectedComm : ${selectedComm && selectedComm.id}`);
    if (!(selectedComm && selectedComm.id === commandId)) {
      logging.debug(`Recherche de la commande : ${commandId}`)
      dispatch(fetchSingleCommand({ serviceId: serviceId!, commandId: commandId! }));
    }
  }, [serviceId, commandId, selectedComm]);

  useEffect(() => {
    if (commandQueryStatus === 'success' && (comm == undefined ||selectedComm.id !== comm.id)) {
      logging.debug(`Commande : ${commandId} chargée`);
      dispatch(pushCommand({ command: selectedComm }));
      dispatch(editCommand(selectedComm));
    }
  }, [commandQueryStatus, selectedComm]);

  useEffect(() => {
    if (commandSaveStatus === 'success') {
      navigate(`/service/${serviceId}`);
    }
  }, [commandSaveStatus]);

  const handleResize = () => {
    if (window.innerWidth < window.innerHeight) {
      const coeff = padVisible ? 1 : 0;
      const maxHeight = window.innerHeight - PAD_HEIGHT * coeff - HEADER_HEIGHT - FOOTER_HEIGHT;
      setHeight(maxHeight);
      setGridColumns(1);
    } else {
      const maxHeight = window.innerHeight - HEADER_HEIGHT - FOOTER_HEIGHT;;
      setHeight(maxHeight);
      setGridColumns(2);
    }
  };

  const addGroup =  () =>{
    let group = "Direct";
    if(comm){
      const groups = getAllGroups(comm.items);
      if(groups.length === 1){
        group =  "Suite 1"
      }else if(groups.length>1){
        const lastGroup = groups[groups.length -1]
        const currentIndex = /Suite (\d+)/.exec(lastGroup! as string)
        const nextIndex = 1+ (+currentIndex![1]);
        group = `Suite ${nextIndex}`;
      }
    }
     dispatch(setCurrentGroup(group));
  };

  const updateTab = (comm?:Command) => {
      let _tabs = [...DEFAULT_TABS];
      if (comm?.status === 'ENDED') {
        setPadVisible(false);
        setTabs([
          ..._tabs,
          {
            key: 'Ouvrir',
            title: 'Ouvrir',
            icon: <FontAwesomeIcon icon={'pen-to-square'} />,
          },
        ]);
      } else {
        if(padVisible){
          _tabs.push({
            key: 'loupe',
            title: 'loupe',
            icon: <FontAwesomeIcon icon={'magnifying-glass'} />,
          });
        }else{
          _tabs.push({
            key: 'loupe',
            title: 'Edition',
            icon: <FontAwesomeIcon icon={'pen'} />,
          });
        }
        setTabs([
          ..._tabs,
          {
            key: 'Cloturer',
            title: 'Cloturer',
            icon: <FontAwesomeIcon icon={'right-from-bracket'} />,
          },
        ]);
      }
  }

  useEffect(() => {
   /* if (comm === undefined) {
      navigate(`/service/${serviceId}`);
      return;
    }*/
   if(comm){
    updateTab(comm)
    dispatch(setCurrentGroup(getLastGroup(comm.items)));
    handleResize();
    window.addEventListener("resize", handleResize);
   }
  }, [comm]);

  const addItem = (item: PlatItem) => {
    dispatch(addPlate(item));
  };

  const onChangeQuantity = (item: PlatItem, quantity: number) => {
    const payload = [item, quantity];
    dispatch(changeQuantity(payload));
  };

  const onDuplicate = (item:PlatItem) =>{
       dispatch(addPlate(item));
  };
  const onEdit = (item:PlatItem) =>{
     dispatch(selectedPlate(item.originalPlate));
     navigate(`plate/${item.id!}`);
  }

  const pushCommandAndSend = () => {
    const query: PushCommandQuery = {
      command: comm,
      processSend: true,
    };

    let newCommand = Object.assign({}, comm);
    const oldItems = newCommand.items.filter((it) => it.status !== "NEW");
    const newCommandItems = newCommand.items.filter((it) => it.status === "NEW").map((it) => Object.assign({}, it));
    newCommandItems.forEach((it) => (it.status = "SENDING"));
    newCommand.items = [...oldItems, ...newCommandItems];

    dispatch(pushCommand(query));
    dispatch(saveCommand(newCommand));

    const catBar = newCommandItems.filter((it) => findCategory(it.originalPlate?.category!).printType == 'bar');
    const catCuisine = newCommandItems.filter((it) => findCategory(it.originalPlate?.category!).printType == 'cuisine');
    
    if(catBar.length>0){
      dispatch(print(generatePrint("bar", comm, catBar)));
    }
    if (catCuisine.length > 0) {
      dispatch(print(generatePrint('cuisine', comm, catCuisine)));
    }
    
  };

  const generatePrint = (name: string, comm: Command, items: CommandItem[]) => {
    const printQuery = {
      id: uuidv4(),
      creationDate: new Date().getTime(),
      printerType: name,
      tableInfo: {
        id: comm.id,
        nbPersons: comm.nbPersons,
        tableName: comm.tableName,
      },
      items: items,
    } as PrintModel;

    return printQuery;
  };

  const pushCommandWithoutSend = () => {
    const query: PushCommandQuery = {
      command: comm,
      processSend: false,
    };
    dispatch(pushCommand(query));
    dispatch(saveCommand(comm));
  };

  const closeCommand = () => {
    let newCommand = Object.assign({}, comm);
    newCommand.status = 'ENDED';
    dispatch(saveCommand(newCommand));
  }

  const openCommand = () => {
    let newCommand = Object.assign({}, comm);
    newCommand.status = 'IN_PROGRESS';
    dispatch(saveCommand(newCommand));
     const query: PushCommandQuery = {
       command: newCommand,
       processSend: false,
     };
    dispatch(pushCommand(query));
   
  };


  useEffect(() =>{
    handleResize();
    updateTab(comm);
  }, [padVisible, comm]);

  const navigateBar = (key: string) => {
    logging.debug(`Action : ${key}`)
    switch (key) {
      case 'home':
        pushCommandWithoutSend();
        break;
      case 'Cloturer':
        closeCommand();
        break;
      case 'Ouvrir':
        openCommand();
        break;
      case 'loupe':
        if(comm && comm.status !=='ENDED'){
          setPadVisible(!padVisible);
        }
        break;
      case "facture":
        navigate(`/service/${comm.serviceId}/bill/${comm.id}`)
        break;
      default:
      //   navigate(`/service/${id}/bar`);
    }
  };

  return (
    clientInfo && (
      <div className='app'>
        <Header hasPrevious={true} redirectAction={pushCommandWithoutSend}>
          <div style={{ display: 'flex', flexDirection: 'row' }} className='header-commande'>
            <div> Table {clientInfo}</div>
            <div> {comm?.nbPersons} pers.</div>
            <div> {comm && getTotal(comm)} &euro;</div>
          </div>
        </Header>
        {comm && (<div className='body'>
          <Grid columns={gridColumns}>
            <GridItem style={{ backgroundColor: "#efefef" }}>
              <Client
                num={clientInfo}
                onDuplicate={onDuplicate}
                nbPersons={comm.nbPersons}
                data={items}
                onEdit={onEdit}
                onChangeQuantity={onChangeQuantity}
                height={height}
              ></Client>
            </GridItem>
            {padVisible && (<GridItem>
              <Pad addItem={addItem} sendCommand={pushCommandAndSend} addNewGroup={addGroup}></Pad>
            </GridItem>)
            }
          </Grid>
        </div>)}
        <div className='bottom'>
          <TabBar activeKey={null} onChange={(item) => navigateBar(item)}>
            {tabs.map((item) => (
              <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
            ))}
          </TabBar>
        </div>
      </div>
    )
  );
};

export { CommandePage };
