import { GET_ALL_PLATE } from "../redux/actionTypes";

const getAllPlates = (content: any) => ({
  type: GET_ALL_PLATE,
  payload: {
    content,
  },
});

export { getAllPlates };
