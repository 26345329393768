const logging = {
    debug : (message:string) =>{
        console.log(message)
    },
    error : (message: string) =>{
        console.error(message);
    }
}


export default logging;
