import { createSlice } from "@reduxjs/toolkit";
import { PlateDetail } from "../models/plate";

export type TopycType = {
  type: string;
  name: string;
  prefix?: string;
  order: number;
  id: string;
  auto?: boolean;
  prize?: number;
};

export type PlateCompositionState = {
  originalPlate?: PlateDetail;
  cuissonMode?: TopycType;
  sauce?: TopycType;
  topics: TopycType[];
};

const initialState: PlateCompositionState = { topics: [] };

export const plateDetailSlice = createSlice({
  name: 'plateDetail',
  initialState,
  reducers: {
    selectedPlate: (state, { type, payload }) => {
      state.originalPlate = payload as PlateDetail;
      state.cuissonMode = undefined;
      state.sauce = undefined;
      state.topics = [];
    },
    changeCuisson: (state, { type, payload }) => {
      state.cuissonMode = payload;
    },
    changeSauce: (state, { type, payload }) => {
      state.sauce = payload;
    },
    addOrRemoveTopic: (state, { type, payload }) => {
      const topics = state.topics.filter((it) => it.id !== payload.id);
      if (topics.length === state.topics.length) {
        state.topics = [...state.topics, payload];
      } else {
        state.topics = topics;
      }
    },
    setTopics: (state, { type, payload }) => {
        state.topics = [...payload]
    },
  },
});

export const { selectedPlate, changeCuisson, changeSauce, addOrRemoveTopic, setTopics } = plateDetailSlice.actions;

export const selectOriginalPlate = (state: any): PlateDetail => {
  return state.plateDetailReducer.originalPlate;
};

export const selectCuissonMode = (state: any) => {
  return state.plateDetailReducer.cuissonMode;
};

export const selectSauce = (state: any) => {
  return state.plateDetailReducer.sauce;
};

export const selectTopics = (state: any) => {
  return state.plateDetailReducer.topics;
};

const plateDetailReducer = plateDetailSlice.reducer;
export default plateDetailReducer;
