import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import logging from '../helper/LoggingHelper';
import { CommandItem } from '../models/plate';
import cacheService from './storage';

const CACHE_PRINTER_NAME = 'printersC';

const epson = (window as any).epson;

export type Status  = "idle"|"loading"|"sucess"|"failed";

cacheService.createCache("printers")

cacheService.createCache(CACHE_PRINTER_NAME);

export type ServieState = {
  status?: Status;
  message?: string;
};

export type PrinterStatus = {
   id: string;
   status?: boolean;
}

export type PrintersState = {
  items?: PrinterModel[];
  printersStatus: PrinterStatus[];
} & ServieState;

export type PrinterState ={
} & ServieState;

export type SinglePrinterState ={
  item?: PrinterModel
} & ServieState;

export type PrinterModel = {
  id: string;
  name: string;
  ip: string;
  categories: string[];
};

export const fetchPrinter = createAsyncThunk('services/fetchPrinter', async (id:string) => {
  try {
    var query = await fetch(`${process.env.REACT_APP_URL_SERVER}/printers/${id}`);
    var ret = (await query.json()) as PrinterModel;
    return ret;
  } catch (e) {
    throw e;
  }
});


export const fetchPrinterStatus = createAsyncThunk('services/fetchPrinterStatus', async (printer:PrinterModel) => {
    logging.debug(`Verification statut imprimante : ${printer.id}`);
    const device = new epson.ePOSDevice();
    try{
      var status:boolean = await new Promise(function(fulfilled, rejected) {
      device.connect(printer.ip, 8043, (data:string) => {
        if(data === "OK"){
            fulfilled(true)
        }else{
          rejected(false)
        }
        })
      });
     
      return { id: printer.id, status: status};
  }catch(e){
      return { id: printer.id, status: false };
  }finally{
     device.disconnect();
  }
});



const  findPrinters = async () =>{
 try {
 /*  var printers = await cacheService.getItemsFromCache(CACHE_PRINTER_NAME);
   if (printers.length > 0) {
     return printers.map((it) => it.payload);
   }*/
   var query = await fetch(`${process.env.REACT_APP_URL_SERVER}/printers`);
   var ret = (await query.json()) as PrinterModel[];
   const _printers = ret as [PrinterModel];
   _printers.forEach((it) => cacheService.putItemInCache(CACHE_PRINTER_NAME, it.id, it));
   return _printers;
 } catch (e) {
   throw e;
 }
}

export const fetchPrinters = createAsyncThunk('services/fetchPrinters', async () => {
 return findPrinters();
});

export const savePrinter = createAsyncThunk('services/savePrinter', async (printer:PrinterModel) => {
  try {
    await fetch(`${process.env.REACT_APP_URL_SERVER}/printers`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(printer),
    });
  } catch (e) {
    throw e;
  }
});

export type PrintTableInfo = {
  id: string;
  tableName: string;
  nbPersons: number;
};

export type PrintType = "bill"|"bar"|"cuisine";

export type PrintModel = {
  id: string;
  creationDate: number;
  printerType: PrintType
  tableInfo: PrintTableInfo;
  items: CommandItem[];
};

export const print = createAsyncThunk('services/print', async (printer: PrintModel) => {
  cacheService.putItemInCache('printers', printer.id, printer);
  try{
    const printers = await findPrinters() as PrinterModel[] ;
    const p = printers.filter(it => it.categories.indexOf(printer.printerType) > 0);
    if(p.length > 0){
      const request = generatePrint(printer).toString();
      console.log('Request', request);

      var address = `https://${p[0].ip}/cgi-bin/epos/service.cgi?devid=local_printer&timeout=10000`;
      //Create an ePOS-Print object
      var epos = new epson.ePOSPrint(address);
      await epos.send(request);

      console.log('...');
/*
      const device = new epson.ePOSDevice();
      device.connect(p[0].ip, 8008, (data:string) => {
      if(data === "OK"){
        const request = generatePrint(printer).toString();
        console.log("Request", request);

      var address = `http://${p[0].ip}/cgi-bin/epos/service.cgi?devid=local_printer&timeout=10000`;
      //Create an ePOS-Print object
      var epos = new epson.ePOSPrint(address);
      epos.send(request);

        console.log("...")
      }else{
        logging.error("Imprimante non disponible")
      }*/
      }
  }catch(e){
    console.error(e)
  }

});



export const printBill = createAsyncThunk('services/printBill', async (data:string) => {
  try {
    const printers = (await findPrinters()) as PrinterModel[];
    const p = printers.filter((it) => it.categories.indexOf("bill") > 0);
    if (p.length > 0) {
      const request = data;
      console.log('Request', request);

      var address = `https://${p[0].ip}/cgi-bin/epos/service.cgi?devid=local_printer&timeout=10000`;
      //Create an ePOS-Print object
      var epos = new epson.ePOSPrint(address);
      await epos.send(request);

      console.log('...');
      /*
      const device = new epson.ePOSDevice();
      device.connect(p[0].ip, 8008, (data:string) => {
      if(data === "OK"){
        const request = generatePrint(printer).toString();
        console.log("Request", request);

      var address = `http://${p[0].ip}/cgi-bin/epos/service.cgi?devid=local_printer&timeout=10000`;
      //Create an ePOS-Print object
      var epos = new epson.ePOSPrint(address);
      epos.send(request);

        console.log("...")
      }else{
        logging.error("Imprimante non disponible")
      }*/
    }
  } catch (e) {
    console.error(e);
  }
});


export const findPrinterJobs = createAsyncThunk('services/printerJob', async () => {
  const items = await cacheService.getItemsFromCache("printers");
  return items.map(it => it.payload as PrintModel).sort((a, b) => b.creationDate - a.creationDate);
});


export const cleanJobs = createAsyncThunk('services/printerClean', async () => {
  cacheService.removeAllItemsFromCache('printers')
  return true;
});

export const generatePrint = (printer: PrintModel) =>{
    const printeBuilder = new epson.ePOSBuilder();
    
    printeBuilder.addTextSize(2, 2);
    printeBuilder.addTextStyle(true, false, false);

    printeBuilder.addFeedLine(1);
    printeBuilder.addText(`  Table ${printer.tableInfo.tableName} - (${printer.tableInfo.nbPersons}p)\n`);
    printeBuilder.addHLine(10, 526, printeBuilder.LINE_THIN);

     printeBuilder.addTextStyle(false, false, false);

    printeBuilder.addFeedLine(1);
    printeBuilder.addTextAlign(printeBuilder.ALIGN_LEFT);
   

    printer.items.forEach(it =>{
      printeBuilder.addTextSize(2, 2);
      printeBuilder.addTextPosition(10);
      printeBuilder.addText(`${it.quantity} x ${it.originalPlate?.name}\n`);
       printeBuilder.addTextSize(1, 1);
      it.topics?.forEach(topic =>{
         printeBuilder.addText(`  - ${topic.name}\n`);
      })
      if(it.ice){
         printeBuilder.addText(`  - ${it.ice ? "Avec Glacon":"Sans Glacon"}\n`);
      }

      if(it.comment){
        printeBuilder.addText(`  - ${it.comment}\n`);
      }
    });

    printeBuilder.addCut(printeBuilder.CUT_FEED);
    return printeBuilder;
}


export const printerJobsSlice = createSlice({
  name: 'printerJobs',
  initialState: {
    status: 'idle',
    items: [] as PrintModel[],
  },
  reducers: {
  },
  extraReducers(builder) {
    builder
      .addCase(findPrinterJobs.pending, (state, action) => {
        state.status = 'loading';
        state.items = [];
      })
      .addCase(findPrinterJobs.fulfilled, (state, action) => {
        state.status = 'sucess';
        state.items = action.payload as [PrintModel];
      })
      .addCase(findPrinterJobs.rejected, (state, action) => {
        state.status = 'failed';
        state.items = [];
      })
      .addCase(cleanJobs.fulfilled, (state, action) =>{
         state.status =  'idle';
         state.items = []
      });
  },
});

const printerJobsReducer = printerJobsSlice.reducer;

export const selectPrinterJobs = (state: any): [PrintModel] => {
  return state.printerJobsReducer.items;
}

export const selectPrinterJobsStatus = (state: any): string => {
  return state.printerJobsReducer.status;
};;


export const printersSlice = createSlice({
  name: 'printersDetail',
  initialState: {
    status: 'idle',
    printersStatus: [] as PrinterStatus[]
  } as PrintersState,
  reducers: {
    resetPrinters: (state) => {
      state.status = 'idle';
      state.items = [];
      state.printersStatus = [];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPrinterStatus.fulfilled, (state, action) => {
        const statusPrinter = {
          "id": action.payload.id, 
          "status": action.payload.status
        };
        const printersStatus = [statusPrinter, ...state.printersStatus.filter(it => it.id !== action.payload.id)];
        state.printersStatus = printersStatus;
      })
      .addCase(fetchPrinterStatus.rejected, (state, action) => {
        state.status = 'loading';
        state.items = [];
      })
      .addCase(fetchPrinters.pending, (state, action) => {
        state.status = 'loading';
        state.items = [];
      })
      .addCase(fetchPrinters.fulfilled, (state, action) => {
        state.status = 'sucess';
        state.items = action.payload as [PrinterModel];
      })
      .addCase(fetchPrinters.rejected, (state, action) => {
        state.status = 'failed';
        state.items = [];
      });
  },
});


export const selectPrinters = (state: any): [PrinterModel] => {
  return state.printersReducer.items;
};


export const selectPrintersStatus = (state: any): Status => {
  return state.printersReducer.status;
};

export const selectPrinterStatus = (state: any): [PrinterStatus] => {
  return state.printersReducer.printersStatus;
};

export const { resetPrinters } = printersSlice.actions;
const printersReducer = printersSlice.reducer;


export const printerSlice = createSlice({
  name: 'printerDetail',
  initialState: {} as PrinterState,
  reducers: {
    resetPrinter: (state, { type, payload }) => {
      state.status = 'idle';
    },
  },
  extraReducers(builder) {
    builder
      .addCase(savePrinter.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(savePrinter.fulfilled, (state, action) => {
        state.status = 'sucess';
      })
      .addCase(savePrinter.rejected, (state, action) => {
        state.status = 'failed';
      });
  },
});

export const printerSaveState = (state: any): Status => {
  return state.printerReducer.status;
};


const printerReducer = printerSlice.reducer;
export const { resetPrinter } = printerSlice.actions;


export const getPrinterlice = createSlice({
  name: 'singlePrinterDetail',
  initialState: {
    status: 'idle',
  } as SinglePrinterState,
  reducers: {
    resetSinglePrinter: (state, { type, payload }) => {
      state.status = 'idle';
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPrinter.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchPrinter.fulfilled, (state, action) => {
        state.status = 'sucess';
        state.item = action.payload as PrinterModel;
      })
      .addCase(fetchPrinter.rejected, (state, action) => {
        state.status = 'failed';
      });
  },
});

export const singlePrinterState = (state: any): Status => {
  return state.singlePrinterReducer.status;
};

export const singlePrinter = (state: any): PrinterModel => {
  return state.singlePrinterReducer.item;
};

const singlePrinterReducer = getPrinterlice.reducer;
export const { resetSinglePrinter } = getPrinterlice.actions;
export { printersReducer, printerReducer, singlePrinterReducer , printerJobsReducer};