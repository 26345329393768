import { configureStore } from "@reduxjs/toolkit";
import plateReducer from "../service/configuration";
import plateDetailReducer from "../service/plate-detail";
import clientReducer from "../service/client";
import { serviceReducer, commandReducer, serviceEnableReducer, qeuryCommandReducer } from '../service/service';
import { printerJobsReducer, printerReducer, printersReducer, singlePrinterReducer } from '../service/printer';
// ...

const store = configureStore({
  reducer: {
    plateReducer,
    plateDetailReducer,
    clientReducer,
    serviceReducer,
    commandReducer,
    serviceEnableReducer,
    printersReducer,
    printerReducer,
    singlePrinterReducer,
    printerJobsReducer,
    qeuryCommandReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export { store };
