import { ListItem } from 'antd-mobile/es/components/list/list-item';
import { PlateDetail } from '../../models/plate';
import './cell-item.css';
import { FloatingBubble, List } from 'antd-mobile';
import Header from '../header/header';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { fetchPlates, selectPlateStatus, selectPlates } from '../../service/configuration';
import { AppDispatch } from '../pad/pad';
import { AddCircleOutline,  StarOutline } from 'antd-mobile-icons';
import Price from '../misc/price';
import { ScrollContent } from '../misc/scrollcontent';

export type CellItemProps = {
  plateDetail: PlateDetail;
  onSelect: (plateDetail: PlateDetail) => void;
};

type DrinkByCategory = {
  subCategory: string;
  drinks: PlateDetail[];
  onSelect?: (plateDetail: PlateDetail) => void;
};

const CellItem = ({ plateDetail, onSelect }: CellItemProps) => {
  const hasDetails =
    false ||
    plateDetail.automaticallyAdd ||
    plateDetail.hasCuissonMode ||
    plateDetail.hasTopics ||
    plateDetail.hasSauce ||
    plateDetail.hasIce ||
    plateDetail.topics ||
    plateDetail.vat;

  return (
    <ListItem key={plateDetail.id} onClick={() => onSelect(plateDetail)}>
      <div className={`table-flex item-${plateDetail.state}`}>
        <div className='star'>
          <StarOutline color={plateDetail.favorite ? 'red' : 'black'} />
        </div>
        <div className='plate'>
          <div>{plateDetail.name}</div>
          {hasDetails && (
            <ul className='detail'>
              {plateDetail.vat && plateDetail.vat.length > 0 && <li>{plateDetail.vat} %</li>}
              {plateDetail.automaticallyAdd && <li>Cumulatif</li>}
              {plateDetail.hasCuissonMode && <li>Cuisson</li>}
              {plateDetail.hasTopics && <li>Accomppagnements</li>}
              {plateDetail.hasSauce && <li>Sauce</li>}
              {plateDetail.hasIce && <li>Glaçon</li>}
              {plateDetail.topics && plateDetail.topics.map((it) => <li key={it.subName}>{it.subName}</li>)}
            </ul>
          )}
        </div>
        {plateDetail.prize && (
          <div className='prize'>
            <Price price={plateDetail.prize} />
          </div>
        )}
      </div>
    </ListItem>
  );
};

export type CategoryDetailProps = {
  plateDetails: PlateDetail[];
  subCategory: string;
  onSelect: (plateDetail: PlateDetail) => void;
};

const CategoryDetail = ({ subCategory, plateDetails, onSelect }: CategoryDetailProps) => {
  return (
    <List header={subCategory} className='category-items'>
      {plateDetails
        .sort((i1, i2) => i1.name.localeCompare(i2.name))
        .map((plateDetail) => (
          <CellItem plateDetail={plateDetail} key={plateDetail.id} onSelect={() => onSelect(plateDetail)}></CellItem>
        ))}
    </List>
  );
};

export type ListPlateDetailProps = {
  category: string;
};

const ListPlateDetail = ({ category }: ListPlateDetailProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const status = useSelector(selectPlateStatus);
  const plates = useSelector(selectPlates);
  const [drinks, setDrinks] = useState<DrinkByCategory[]>([]);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchPlates());
    }

    if (status === 'succeeded') {
      const drinkByCat: Record<string, PlateDetail[]> = {};
      plates
        .filter((item) => item.category === category)
        .forEach((drink) => {
          if (drinkByCat[drink.subCategory!] === undefined) {
            drinkByCat[drink.subCategory!] = [];
          }
          drinkByCat[drink.subCategory!] = [...drinkByCat[drink.subCategory!], drink];
        });

      let tabSub = [];
      for (var name in drinkByCat) {
        const tab = {
          subCategory: name,
          drinks: drinkByCat[name],
        };
        tabSub.push(tab);
      }
      setDrinks(tabSub);
    }
  }, [plates, status]);

  return (
    <div>
      <Header title={category} hasPrevious={true} redirectUrl='/params'displayMenu></Header>
      <ScrollContent compoenentsHeight={40}>
        <div className='body'>
          {drinks
            .sort((i1, i2) => i1.subCategory.localeCompare(i2.subCategory))
            .map((item) => (
              <CategoryDetail
                key={item.subCategory}
                plateDetails={item.drinks}
                subCategory={item.subCategory}
                onSelect={(p) => {
                  navigate(`${p.id}`);
                }}
              />
            ))}
          <FloatingBubble
            axis='xy'
            magnetic='x'
            style={{
              '--initial-position-bottom': '24px',
              '--initial-position-right': '24px',
              '--edge-distance': '24px',
            }}
          >
            <AddCircleOutline fontSize={32} onClick={() => navigate('edit')} />
          </FloatingBubble>
        </div>
      </ScrollContent>
    </div>
  );
};

export default ListPlateDetail;
