import localforage from "localforage";

type CacheItem<T> = {
  key: string;
  payload: T;
  createdDate: number;
  isExpired?: boolean;
  expirationDate: number;
};

const defaultStorage = localforage.createInstance({
  driver: localforage.LOCALSTORAGE, // Force WebSQL; same as using setDriver()
  name: "catsberg",
  version: 1.0,
  size: 4980736, // Size of database, in bytes. WebSQL-only for now.
  storeName: "catsberg", // Should be alphanumeric, with underscores.
  description: "some description",
});

class CacheService {
  cacheMap = new Map<string, LocalForage>();

  createCache(cacheName: string) {
    this.cacheMap.set(
      cacheName,
      localforage.createInstance({
        driver: localforage.LOCALSTORAGE, // Force WebSQL; same as using setDriver()
        name: cacheName,
        version: 1.0,
        size: 4980736, // Size of database, in bytes. WebSQL-only for now.
        storeName: cacheName, // Should be alphanumeric, with underscores.
        description: 'some description',
      })
    );
  }

  putItem(key: string, payload: any) {
    const item = {
      key: key,
      payload: payload,
      createdDate: new Date().getTime(),
      expirationDate: new Date().getTime() + 1000 * 10,
    };
    defaultStorage.setItem(key, item);
  }

  putItemInCache(cacheName: string, key: string, payload: any) {
    const item = {
      key: key,
      payload: payload,
      createdDate: new Date().getTime(),
      expirationDate: new Date().getTime() + 1000 * 10,
    };
    this.cacheMap.get(cacheName)?.setItem(key, item);
  }

  async getItem<T>(key: string): Promise<CacheItem<T> | null> {
    let item = await defaultStorage.getItem<CacheItem<T>>(key);
    if (item) {
      const ret = Object.assign(item, { isExpired: new Date().getTime() > item.expirationDate });
      return ret;
    }
    return null;
  }

  async getItemFromCache<T>(cacheName: string, key: string): Promise<CacheItem<T> | null> {
    let item = await this.cacheMap.get(cacheName)?.getItem<CacheItem<T>>(key);
    if (item) {
      const ret = Object.assign(item, { isExpired: new Date().getTime() > item.expirationDate });
      return ret;
    }
    return null;
  }

  async getItemsFromCache<T>(cacheName: string): Promise<CacheItem<T>[]> {
    const keys = await this.cacheMap.get(cacheName)?.keys();
    let ret = [] as CacheItem<T>[];

    for (const key of keys!) {
      const item = (await this.getItemFromCache(cacheName, key)) as CacheItem<T>;
      ret.push(item);
    }

    return ret;
  }
  async removeAllItemsFromCache<T>(cacheName: string) {
    const keys = await this.cacheMap.get(cacheName)?.keys();
    keys?.forEach(key => this.cacheMap.get(cacheName)?.removeItem(key))
  }
}

const cacheService = new CacheService();

export default cacheService;
