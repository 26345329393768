import { createSlice } from "@reduxjs/toolkit";
import { Command, CommandItem } from "../models/plate";
import { v4 as uuidv4 } from "uuid";
import { TopycType } from './plate-detail';
import logging from '../helper/LoggingHelper';

export type ClientState = {
  command?: Command;
  currentGroup?: string
};

const initialState: ClientState = {};

export const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setCurrentGroup: (state, { type, payload }) => {
      state.currentGroup = payload;
    },
    editCommand: (state, { type, payload }) => {
      logging.debug(`Edition de la commande : ${payload.id}`);
      state.command = payload;
    },
    resetCommand: (state) => {
      state.command = undefined;
    },
    addPlate: (state, { type, payload }) => {
      logging.debug(`Ajout du plat : ${payload}`);
      const topics = (payload.topics || []) as [TopycType];
      const totalPrize = (topics.reduce((acc, item) => acc + Number(item.prize || 0), 0) + Number(payload.originalPlate.prize || 0)).toFixed(2);
      if (payload.commandItem) {
        const item: CommandItem = {
          id: payload.commandItem.id,
          originalPlate: payload.originalPlate,
          quantity: payload.quantity,
          topics: payload.topics,
          description: payload.description,
          status: payload.commandItem.status,
          comment: payload.comment,
          group: payload.commandItem.group,
          automaticallyAdd: false,
          ice: payload.ice,
          unitPrize: totalPrize,
          order: payload.commandItem.order,
        };
        if (state.command) {
          state.command.items = [...state.command.items.filter((it) => it.id !== payload.commandItem.id), item];
        }
      } else {
        //recherche de l'element identique
        const current = state.command?.items ?? [];
        const currentGroup = state.currentGroup ?? 'Direct';
        const it = current
          .filter((it) => it.status === 'NEW' && it.originalPlate && it.originalPlate.id === payload.originalPlate.id)
          .filter((item) => item.group && item.group === currentGroup)
          .filter((item) => item.automaticallyAdd ?? false);
        if (it.length === 1) {
          it[0].quantity += payload.quantity || 1;
          if (state.command) {
            state.command.items = [...state.command.items];
          }
        } else {
          const nextOrder =
            ((state.command?.items || [])
              .map((it) => it.order || 1.0)
              .sort()
              .reverse()[0] || 0) + 1;

          const item: CommandItem = {
            id: uuidv4(),
            originalPlate: payload.originalPlate,
            quantity: payload.quantity || 1,
            topics: payload.topics,
            description: payload.description,
            status: 'NEW',
            comment: payload.comment,
            group: state.currentGroup,
            ice: payload.ice,
            automaticallyAdd: payload.originalPlate.automaticallyAdd,
            unitPrize: totalPrize,
            order: nextOrder,
          };
          if (state.command) {
            state.command.items = [...state.command.items, item];
          }
        }
      }

      logging.debug(`Payload commande : ${JSON.stringify(state.command)}`);
    },
    changeQuantity: (state, { type, payload }) => {
      const [item, quantity] = payload;

      if (state.command) {
        const items = state.command.items;
        if (quantity <= 0) {
          state.command.items = items.filter((it) => it.id !== item.id);
        } else {
          let it = items.filter((it) => it.id === item.id)[0];
          it.quantity = quantity;
        }
      }
    },
    sendCommand: (state) => {
      if (state.command) {
        const oldItems = state.command.items.filter((it) => it.status !== 'NEW');
        const newCommandItems = state.command.items.filter((it) => it.status === 'NEW');
        newCommandItems.forEach((it) => (it.status = 'SENDING'));

        state.command.items = [...oldItems, ...newCommandItems];
      }
    },
  },
});

export const { editCommand, addPlate, changeQuantity, sendCommand, setCurrentGroup, resetCommand } = clientSlice.actions;

export const selectItems = (state: any) => {
  return state.clientReducer.command?.items;
};

export const selectTableInfo = (state: any) => {
  return state.clientReducer.command?.tableName;
};

export const selectEditCommand = (state: any): Command => {
  return state.clientReducer.command;
};

const clientReducer = clientSlice.reducer;
export default clientReducer;
