import ListPlateDetail from '../../components/configuration/cell-item';

const ConfigStarter = () => {
  return (
    <div>
      <ListPlateDetail category='entree' />
    </div>
  );
};

export default ConfigStarter;
