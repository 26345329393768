import {  ErrorBlock,  List,  TabBar } from 'antd-mobile';
import Header from '../../components/header/header';
import { ScrollContent } from '../../components/misc/scrollcontent';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logging from '../../helper/LoggingHelper';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PrintModel, cleanJobs, findPrinterJobs, generatePrint, selectPrinterJobs, selectPrinterJobsStatus } from '../../service/printer';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import { ListItem } from 'antd-mobile/es/components/list/list-item';

const DEFAULT_TABS = [
  {
    key: 'printers',
    title: 'Imprimantes',
    icon: <FontAwesomeIcon icon={'gear'} />,
  },
  {
    key: 'clean',
    title: 'Vider',
    icon: <FontAwesomeIcon icon={'trash-can'} />,
  },
];

const PrintJobs = () =>{
  const dispatch = useDispatch<AppDispatch>();
  let status = useSelector<string>(selectPrinterJobsStatus);
  let printersJobs = useSelector(selectPrinterJobs)
  let [jobs, setJobs] = useState<PrintModel[]>([])
  let [printView, setPrintView] = useState<string>("");

 useEffect(()=>{
  if(status === "idle"){
    dispatch(findPrinterJobs());
  }else if(status =="sucess"){
    setJobs(printersJobs);
  }
 }, [status, printersJobs])

 

  let [tabs, setTabs] = useState(DEFAULT_TABS);
    const navigate = useNavigate();    
    const navigateBar = (key: string) => {
      logging.debug(`Action : ${key}`);
      switch (key) {
        case 'home':
          break;
        case 'Cloturer':
          break;
        case 'Ouvrir':
          break;
        case 'clean':
          dispatch(cleanJobs());
          break;
        case 'printers':
          navigate('/printers');
          break;
        default:
        //   navigate(`/service/${id}/bar`);
      }
    };

    return (
      <div className='app'>
        <Header displayMenu title='Impression' hasPrevious={false}></Header>
        <div className='body'>
          <ScrollContent compoenentsHeight={80}>
            <List>
              {jobs &&
                jobs.map((it) => (
                  <ListItem className='printer' key={it.id} onClick={() => setPrintView(generatePrint(it).toString())}>
                    <div className='content'>
                      <div className='detail'>
                        <div className='name'>Table {it.tableInfo.tableName}</div>
                        <div className='detail'>{it.items.length} Elements</div>
                      </div>
                    </div>
                  </ListItem>
                ))}
            </List>
            <textarea rows={40} cols={40} defaultValue={printView}></textarea>
          </ScrollContent>
        </div>
        <div className='bottom'>
          <TabBar activeKey={null} onChange={(item) => navigateBar(item)}>
            {tabs.map((item) => (
              <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
            ))}
          </TabBar>
        </div>
      </div>
    );
}

export {PrintJobs};