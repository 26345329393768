import { Dialog, Grid, List} from "antd-mobile";
import "./client.css";
import { useCallback, useEffect, useRef, useState } from "react";
import { CommandItem, PlatItem } from "../../models/plate";
import { DeleteOutline, EditSFill } from "antd-mobile-icons";
import { categories } from '../../service/configuration';
import { LongPressEventType, useLongPress } from 'use-long-press';

type ClientProp = {
  num: number;
  nbPersons: number;
  data: CommandItem[];
  onChangeQuantity: (item: PlatItem, quantity: number) => void;
  onDuplicate: (item: PlatItem) => void;
  onEdit: (item: PlatItem) => void;
  height: number;
};

type ClientItemProps = {
  id: string;
  name?: string;
  quantity: number;
  category?: string;
  description?: String;
  onChangeQuantity: (quantity: number) => void;
  status?: string;
  onClick?: (action: string, id: string) => void;
  onDelete?: (id: string) => void;
  price?: number;
};


const DrawItem = ({ name, quantity, category, description, id, onChangeQuantity, 
  status, onClick, onDelete, price }: ClientItemProps) => {
    return (
      <Grid columns={2} gap={8}>
        <div>
          <span style={{ fontWeight: '900' }}> {quantity}x</span>
          <span style={{ textTransform: 'capitalize', paddingLeft: '5px' }}>{name}</span>
        </div>
        <div>
          <div className='quantity'>{(quantity * (price || 0)).toFixed(2)} &euro;</div>
        </div>
        <>
          <div className='description'>{description}</div>
          {status === 'NEW' && (
            <div className='stepper'>
              <DeleteOutline fontSize={28} onClick={() => onClick!('DELETE', id)} />
              <EditSFill fontSize={28} onClick={() => onClick!('EDIT', id)} />
            </div>
          )}
        </>
      </Grid>
    );
  }

const ClientItem = ({ name, quantity, category, description, id, onChangeQuantity, 
  status, onClick, onDelete, price }: ClientItemProps) => {
     const [enabled, setEnabled] = useState(status !== "NEW");

    const callback = useCallback(() => {
      if(onDelete){
        onDelete(id)
      }
    }, []);

     const bind = useLongPress(enabled ? callback:null, {
       onStart: (event, meta) => {},
       onFinish: (event, meta) => {},
       onCancel: (event, meta) => {},
       //onMove: () => console.log("Detected mouse or touch movement"),
       filterEvents: (event) => true, // All events can potentially trigger long press
       threshold: 1000,
       captureEvent: true,
       cancelOnMovement: false,
       cancelOutsideElement: true,
       detect: LongPressEventType.Pointer,
     });
     const handlers = bind('select item');

  return (
    <List.Item key={id}>
      {enabled ? (
        <div className='unselectable' {...handlers} >
          <DrawItem
            id={id}
            name={name}
            onChangeQuantity={onChangeQuantity}
            quantity={quantity}
            category={category}
            onClick={onClick}
            description={description}
            price={price}
            status={status}
          />
        </div>
      ) : (
        <DrawItem
          id={id}
          name={name}
          onChangeQuantity={onChangeQuantity}
          quantity={quantity}
          category={category}
          description={description}
          onClick={onClick}
          price={price}
          status={status}
        />
      )}
    </List.Item>
  );
};

const Client = ({ num, nbPersons, data, onChangeQuantity, height, onDuplicate, onEdit }: ClientProp) => {
  const scrollDiv = useRef<HTMLDivElement>(null);
  useEffect(() => {
    //const body = scrollDiv.current?.nativeElement?.getElementsByClassName("adm-list-body")[0];
    const body = scrollDiv.current;
    if (body !== undefined) {
      body!.scrollTo({ top: body!.scrollHeight, behavior: 'auto' });
    }
  });

  const onClickItem = (action: string, id: string, item: PlatItem) => {
    switch (action) {
      case 'DUPLICATE':
        onDuplicate(item);
        break;
      case 'DELETE':
        onChangeQuantity(item, item.quantity!-1);
        break;
      case 'EDIT':
        onEdit(item);
        break;
      default:
        break;
    }
    console.log(action, id);
  };

  const onDelete = (id:string, item:PlatItem) =>{
     Dialog.confirm({
       content: (
         <div>
           Souhaitez vous supprimer definivement <b>{item.originalPlate?.name}</b> ?
         </div>
       ),
       cancelText: 'Annuler',
       confirmText: 'Supprimer',
       onConfirm: async () => {
         onChangeQuantity(item, 0);
       },
     });
     
  }

  const sortCommandItem = (s1:CommandItem, s2:CommandItem) => (s1.order||1)-(s2.order||1);

  const getAllGroups = (data: CommandItem[]) =>{
    return Array.from([...data]
      .sort(sortCommandItem)
      .map(it => it.group|| "Direct")
      .reduce((stack, current) => stack.add(current), new Set<string>()))
  }

  const getGroupDetail = (data: CommandItem[]) =>{
    return data.reduce((acc, current) => {
      const subCat = categories.filter(it => it.category==current.originalPlate?.category)
                .flatMap(it => it.subCategories)
                .filter(it => it.name === current.originalPlate?.subCategory)
                .filter(it => it.type !== undefined);
      if(subCat){
        const type = subCat[0].type!;
        acc.set(type,  (acc.get(type)||0)+current.quantity)
      }
      return acc;
    }, new Map<string, number>())
  }

  return (
    <div style={{ height: height + 'px', overflow: 'auto' }} ref={scrollDiv}>
      {getAllGroups(data).map((group) => {
        const items = data
          .filter((it) => it.group == group || (it.group == null && 'Direct' == group))
          .filter((d) => d.quantity !== undefined && d.id !== undefined)
          .sort(sortCommandItem);
        return (
          <div className='group-item' key={group}>
            <div className='group-name'>
              <div>{group}</div>
              <div className='detail'>
                {Array.from(getGroupDetail(items).entries())
                  .sort((i1, i2) => i1[0].localeCompare(i2[0]))
                  .map((entry) => entry[0] + ' : ' + entry[1])
                  .join(', ')}
              </div>
            </div>
            <div className='group-items'>
              <List className='commande' mode='default'>
                {items.sort(sortCommandItem).map((d) => {
                  let description = d.description;
                  if (d.topics && d.topics.length > 0) {
                    description = [...d.topics]
                      .sort((a, b) => a.order - b.order)
                      .map((it) => ((it.prefix ?? '') + ' ' + it.name).trim())
                      .join(', ');
                  }
                  description =
                    (description ? description + ', ' : '') +
                    (d.comment && d.comment !== undefined ? d.comment : '') +
                    (d.originalPlate?.hasIce ? (d.ice ? ' Glacon' : ' Sans Glacon') : '');
                  return (
                    <ClientItem
                      key={d.id}
                      name={d.originalPlate?.name}
                      quantity={d.quantity}
                      category={d.originalPlate?.category}
                      description={description}
                      id={d.id}
                      onChangeQuantity={(quantity) => onChangeQuantity(d, quantity)}
                      status={d.status}
                      onClick={(action, id) => onClickItem(action, id, d)}
                      onDelete={(id) => onDelete(id, d)}
                      price={Number(d.unitPrize || d.originalPlate?.prize || 0)}
                    />
                  );
                })}
              </List>
            </div>
          </div>
        );
      })}
      ;
    </div>
  );
};

export { Client };
