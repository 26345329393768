import { Button, FloatingBubble, List } from 'antd-mobile';
import Header from '../../components/header/header'
import { AddCircleOutline } from 'antd-mobile-icons';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PrinterModel, PrinterStatus, fetchPrinterStatus, fetchPrinters, selectPrinterStatus, selectPrinters, selectPrintersStatus } from '../../service/printer';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import { ListItem } from 'antd-mobile/es/components/list/list-item';
import "./printer.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Printers= ()=>{
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    let status = useSelector(selectPrintersStatus);
    let printers = useSelector(selectPrinters);
    let printerStatus = useSelector(selectPrinterStatus);
;
    useEffect(() =>{
        if(status === "idle"){
          dispatch(fetchPrinters());
        }
    }, [status])

    const onSelect = (item:PrinterModel) =>{
      navigate(`${item.id}`)
    }

    useEffect(()=>{
        printers?.forEach(p => dispatch(fetchPrinterStatus(p)))
    }, [printers])

    const checkState = (id: string) => {
      var status = printerStatus.filter((it) => id === it.id);
      if (status.length > 0) {
        return status[0].status ? <FontAwesomeIcon icon={'print'} color='green'/> : <FontAwesomeIcon icon={'print'} color='red' />;
      }
      return <FontAwesomeIcon icon={'print'}/>;
    };

    return (
      <div>
        <Header title='Imprimantes' displayMenu hasPrevious redirectUrl='/prints' />
        <div className='body'>
          <List>
            {
              printers &&
              printers.map((it) => {
                return (
                  <ListItem className='printer' key={it.id}>
                    <div className='content'>
                      <div className='detail'>
                        <div className='state'>
                          <span>{checkState(it.id)}</span>
                        </div>
                        <div className='name'>{it.name}</div>
                        <div className='ip'>{it.ip}</div>
                      </div>
                      <div className='stepper'>
                        <a href={'https://' + it.ip + '/'} className='button'>
                          <Button color='warning' fill='solid'>
                            Administrer
                          </Button>
                        </a>
                        <a onClick={() => onSelect(it)}>
                          <Button color='primary' fill='solid'>
                            Modifier
                          </Button>
                        </a>
                      </div>
                    </div>
                  </ListItem>
                );
              })}
          </List>

          <FloatingBubble
            axis='xy'
            magnetic='x'
            style={{
              '--initial-position-bottom': '24px',
              '--initial-position-right': '24px',
              '--edge-distance': '24px',
            }}
          >
            <AddCircleOutline fontSize={32} onClick={() => navigate('new')} />
          </FloatingBubble>
        </div>
      </div>
    );
}


export {Printers}