import { Button, Grid, Space, Stepper, TextArea } from "antd-mobile";
import { GridItem } from "antd-mobile/es/components/grid/grid";
import "./plate-commande.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../pad/pad";
import {
  TopycType,
  changeCuisson,
  selectOriginalPlate
} from "../../service/plate-detail";
import { useSelector } from "react-redux";
import { addPlate, selectEditCommand } from "../../service/client";
import Header from "../header/header";
import { useEffect, useState } from "react";
import { categories, selectPlates } from "../../service/configuration";
import { CommandItem, PlateDetail } from "../../models/plate";
import { ScrollContent } from '../misc/scrollcontent';

const allTopics = [
  { type: "sauce", name: "Poivre", prefix: "Sauce", order: 100, id: "1" },
  { type: "sauce", name: "Maroille", prefix: "Sauce", order: 100, id: "2" },
  { type: "sauce", name: "Mt Des cats", prefix: "Sauce", order: 100, id: "3" },
  { type: "sauce", name: "Biere", prefix: "Sauce", order: 100, id: "4" },
  { type: "sauce", name: "Moutarde Ancienne", prefix: "Sauce", order: 100, id: "5" },
  { type: "cuisson", name: "Bleu", prefix: "Cuisson", order: 1, id: "6" },
  { type: "cuisson", name: "Saignant", prefix: "Cuisson", order: 1, id: "7" },
  { type: "cuisson", name: "A Point", prefix: "Cuisson", order: 1, id: "8" },
  { type: "cuisson", name: "Bien Cuit", prefix: "Cuisson", order: 1, id: "9" },
  { type: "accompagnement", name: "Frites", order: 10, id: "10" },
  { type: "accompagnement", name: "Salade", order: 11, id: "11" },
  { type: "accompagnement", name: "Crudités", order: 12, id: "12" },
  { type: "accompagnement", name: "Haricots Verts", order: 13, id: "13" },
];

const PlateCommand = () => {
  const dispatch = useDispatch<AppDispatch>();
  let { plateId } = useParams<string>();
  const navigate = useNavigate();
  const plates = useSelector(selectPlates);
  const item = useSelector(selectOriginalPlate);
  const [comment, setComment] = useState("");
  const [topics, setTopics] = useState<TopycType[]>([]);
  const [ice, setIce] = useState<boolean>(false);
  const [cuissonMode, setCuissonMode] = useState<TopycType>();

  const command = useSelector(selectEditCommand);

  const _categories = categories; 

  let { serviceId } = useParams<string>();
  let { commandId } = useParams<string>();

  let [sauces, setSauces] = useState<TopycType[]>();
  let [glaces, setGlaces] = useState<TopycType[]>();
  let [quantity, setQuantity] = useState<number>(1);

  let [accompagnements, setAccompagnements] = useState<TopycType[]>();
  let [commandItem, setCommandItem] = useState<CommandItem>();

  const hasTopic = (topic:TopycType) =>{
    return topics.filter(it => it.id === topic.id).length >0;
  }

  const setTopic = (topic: TopycType, multiple:boolean = true) => {
   if(hasTopic(topic)){
    setTopics(topics.filter(it => it.id !==topic.id))
   }else{
    let _topics = [...topics]
    if(!multiple){
      _topics = _topics.filter(it => it.type !== topic.type);
    }
    setTopics([..._topics, topic]);
   }
  };

  const validate = (value: string) => {
    if (value === "valider") {
      //TODO Filtre à supprimer
      const selectedTopics = [cuissonMode, ...topics.filter(it=>it.type !== 'cuisson')].filter((it) => it !== undefined);
      let plate = {
        originalPlate: item,
        topics: selectedTopics,
        comment: comment,
        commandItem : commandItem,
        ice: item.hasIce ? ice : undefined,
        quantity: quantity
      };

      dispatch(addPlate(plate));
    }
    navigate(`/service/${serviceId}/commande/${commandId}`);
  };

  const removeSpace = (name: string) => {
    return name ? name.toLowerCase().replaceAll(" ", "-") : "";
  };

  const mapTopic = (it: PlateDetail, type: string, prefix: string) => {
    return {
      id: it.id!,
      type: type,
      prefix: prefix,
      name: it.name,
      order: 1,
      prize: it.prize
    } as TopycType;
  };

  const prepareTopics = (plates: PlateDetail[], category: string, subCategorie: string, topicType: string) => {
    return plates.filter((it) => it.category === category && it.subCategory === subCategorie).map((it) => mapTopic(it, topicType, ""));
  };
  useEffect(() => {
    setSauces(prepareTopics(plates, 'plat', 'Sauce', 'sauce'));
    setAccompagnements(prepareTopics(plates, 'topic', 'accompagnement plat', 'accompagnement'));
    setGlaces(prepareTopics(plates, 'topic', 'boule de glace', 'glace'));

    if (plateId && command) {
      const currentPlates = command.items.filter(it => it.id === plateId);
      if (currentPlates.length === 1) {
        const currentPlate = currentPlates[0];
        const topics = currentPlate.topics || [];

        const topicsType = _categories
          //.filter((it) => it.category === 'topic')
          .flatMap((it) => it.subCategories)
          .filter((it) => it.auto)
          .map((it) => it.name);

        //(it) => it.type === 'accompagnement' || it.type === 'glace' || it.type === 'sirop' || it.type === 'parfum the'  || it.type === 'sirop alcoolise'

        setTopics([...topics.filter(it => topicsType.indexOf(it.type))]);
        setComment(currentPlate.comment || '');
        setCommandItem(currentPlate);
        setCuissonMode([...topics.filter((it) => it.type === 'cuisson')][0]);
        setIce(currentPlate.ice ?? false);
        setQuantity(currentPlate.quantity);

       /* const sauce = topics.filter((it) => it.type === 'sauce');
        if (sauce) {
          setSauce(sauce[0]);
        }*/
      }
      
    }
  }, [plates, plateId, command]);

  return (
    <div className='app plate-commande'>
      <Header title={item?.name} hasPrevious redirectUrl={'/service/' + serviceId + '/commande/' + commandId} />
      {item && (
        <div className='body'>
          <div>
            <h3>Quantité</h3>
            <Stepper
              style={{ width: '100%' }}
              value={quantity}
              min={1}
              max={99}
              onChange={(value) => {
                setQuantity(value);
              }}
            />
          </div>
          <div className='caracteristic'>
            <ScrollContent compoenentsHeight={100}>
              {item.hasCuissonMode && (
                <div>
                  <h3>Cuisson</h3>
                  <Grid columns={2} gap={3}>
                    {allTopics
                      .filter((it) => it.type === 'cuisson')
                      .map((it) => (
                        <GridItem className={'sauce ' + removeSpace(it.name)} key={it.name}>
                          <Button fill={cuissonMode === it ? 'solid' : 'outline'} onClick={() => setCuissonMode(it)}>
                            <div>{it.name}</div>
                          </Button>
                        </GridItem>
                      ))}
                  </Grid>
                  <Space />
                </div>
              )}
              {item.category === 'plat' && (
                <div>
                  <h3>Accompagnement</h3>
                  <Grid columns={2} gap={3}>
                    {accompagnements?.map((it) => (
                      <GridItem className='accompagnement' key={it.name}>
                        <Button fill={hasTopic(it) ? 'solid' : 'outline'} onClick={() => setTopic(it)}>
                          <div>{it.name}</div>
                        </Button>
                      </GridItem>
                    ))}
                  </Grid>
                  <Space />
                </div>
              )}
              {item.hasSauce && (
                <div>
                  <h3>Sauce</h3>
                  <Grid columns={2} className='sauce' gap={3}>
                    {sauces?.map((it) => (
                      <GridItem key={it.name}>
                        <Button fill={hasTopic(it) ? 'solid' : 'outline'} onClick={() => setTopic(it, false)}>
                          <div>{it.name}</div>
                        </Button>
                      </GridItem>
                    ))}
                  </Grid>
                </div>
              )}
              <Space />
              {item.hasIceScream && (
                <div>
                  <h3>Glace</h3>
                  <Grid columns={2} className='sauce' gap={3}>
                    {glaces?.map((it) => (
                      <GridItem key={it.name}>
                        <Button fill={hasTopic(it) ? 'solid' : 'outline'} onClick={() => setTopic(it)}>
                          <div>{it.name}</div>
                        </Button>
                      </GridItem>
                    ))}
                  </Grid>
                </div>
              )}
              {item.hasIce && (
                <div>
                  <h3>Glacon</h3>
                  <Grid columns={2} className='sauce' gap={3}>
                    <GridItem>
                      <Button fill={!ice ? 'solid' : 'outline'} onClick={() => setIce(false)}>
                        <div>Sans Glaçon</div>
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Button fill={ice ? 'solid' : 'outline'} onClick={() => setIce(true)}>
                        <div>Avec Glaçon</div>
                      </Button>
                    </GridItem>
                  </Grid>
                </div>
              )}
              <Space />
              {item.topics &&
                item.topics.map((it) => (
                  <div key={it.subName}>
                    <h3> {it.label || it.subName}</h3>
                    <Grid columns={2} className='sauce' gap={3}>
                      {prepareTopics(plates, it.name, it.subName, it.subName).map((topic) => (
                        <GridItem key={topic.name}>
                          <Button fill={hasTopic(topic) ? 'solid' : 'outline'} onClick={() => setTopic(topic)}>
                            <div>{topic.name}</div>
                          </Button>
                        </GridItem>
                      ))}
                    </Grid>
                  </div>
                ))}
              <h3>Commentaire</h3>
              <TextArea placeholder='Commentaire' value={comment} onChange={setComment} />
            </ScrollContent>
            <Grid columns={2} gap={5}>
              <Button block color='danger' size='large' onClick={() => validate('annuler')}>
                Annuler
              </Button>
              <Button block type='submit' color='success' size='large' onClick={() => validate('valider')}>
                Enregitrer
              </Button>
            </Grid>
          </div>
        </div>
      )}
    </div>
  );
};

export default PlateCommand;
