import Header from "../../components/header/header";
import { EditPlateDetail } from "../../components/configuration/edit-plate";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { categories } from "../../service/configuration";
import { ScrollContent } from '../../components/misc/scrollcontent';

const EditPlate = () => {
  let { id } = useParams<string>();
  let { type } = useParams<string>();
  let [category, setCategory] = useState<string>("");
  let [subCategories, setSubCategories] = useState<string[]>([]);
  let [hasTopics, setHasTopics] = useState<boolean>(false);
  let [hasSauce, setHasSauce] = useState<boolean>(false);
  let [hasCooking, setHasCooking] = useState<boolean>(false);
  let [hasIceScream, setHasIceScream] = useState<boolean>(false);
  let [hasIce, setHasIce] = useState<boolean>(false);

  useEffect(() => {
    if (type !== undefined) {
      var cat = categories.filter((it) => it.category === type);
      if (cat.length) {
        setCategory(cat[0].category);
        setSubCategories(cat[0].subCategories.map(it => it.name));
        setHasTopics(cat[0].hasTopics);
        setHasSauce(cat[0].hasSauce);
        setHasCooking(cat[0].hasSauce);
        setHasIceScream(cat[0].hasIceScream);
        setHasIce(cat[0].hasIce);
      }
    }
  }, [type]);

  return (
    <div>
      <Header title='Edition / Création' redirectUrl={'/params/' + type} hasPrevious={true} displayMenu/>
      <ScrollContent compoenentsHeight={40}>
        <EditPlateDetail
          id={id}
          urlBack={'/params/' + type}
          subCategories={subCategories}
          hasTopic={hasTopics}
          hasSauce={hasSauce}
          hasCooking={hasCooking}
          hasIceScream={hasIceScream}
          category={category}
          hasIce={hasIce}
        />
      </ScrollContent>
    </div>
  );
};

export default EditPlate;
