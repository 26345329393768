import { Command } from '../models/plate';

const  getTotal = (comm: Command) => {
  return comm.items
    ? comm.items
        .map((item) => item.quantity * Number(item.unitPrize || item.originalPlate?.prize || 0))
        .reduce((previous, current) => previous + current, 0)
        .toFixed(2)
    : 0;
};


export default getTotal;