import { Button } from "antd-mobile";
import { useState } from "react";
import { PlatItem } from "../../models/plate";

type DrinkCommandProps = {
  item: PlatItem;
  onQuantitychange: (quantity: number) => void;
};

const DrinkCommand = ({ item, onQuantitychange }: DrinkCommandProps) => {
  let [quantity, setQuantity] = useState(0);
  const onClick = (value: number) => {
    onQuantitychange(value);
    setQuantity(value);
  };
  return (
    <div style={{ textAlign: "center" }}>
      {item.quantities?.map((it) => (
        <Button onClick={() => onClick(it)} fill={quantity === it ? "outline" : "none"} key={it}>
          {it}
        </Button>
      ))}
    </div>
  );
};

export default DrinkCommand;
