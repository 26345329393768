import { PlateDetail } from "../../models/plate";
import { AppOutline } from "antd-mobile-icons";
import ListPlateDetail from "../../components/configuration/cell-item";

const ConfigTopic = () => {
  return (
    <div>
      <ListPlateDetail category="topic" />
    </div>
  );
};

export default ConfigTopic;
