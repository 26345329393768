import { Button, Form, Grid, Input, Selector, Switch, TextArea } from "antd-mobile";
import "./edit-plate.css";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { addPlate, categories, fetchDetailPlate, savePlate, selectSelectedPlate } from "../../service/configuration";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../pad/pad";
import { useSelector } from "react-redux";
import { PlateDetail, TopicModel } from "../../models/plate";
import { v4 as uuidv4 } from "uuid";
import logging from '../../helper/LoggingHelper';

export type EditPlateDetailProps = {
  id?: string;
  urlBack: string;
  category: string;
  subCategories: string[];
  hasTopic: boolean;
  hasSauce: boolean;
  hasIceScream: boolean,
  hasCooking: boolean;
  hasIce: boolean;
};

export const EditPlateDetail = ({ id, urlBack, category, subCategories, hasTopic, hasSauce, hasCooking, hasIceScream, hasIce}: EditPlateDetailProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  let [thePlate, setThePlate] = useState<any>({});
  let plateDetail = useSelector(selectSelectedPlate);
  const [form] = Form.useForm();

  let [configurationTopics, setConfigurationTopics] = useState<TopicModel[]>([]);

  let [loading, setLoading] = useState<boolean>(true);
  let [selectSubCategory, setSelectSubCategory] = useState<string[]>([]);

  const pickerData = subCategories.map((it) => {
    return { label: it, value: it.replaceAll(" ", "-") };
  });

   const vatData = [10, 20].map((it) => {
     return { label: it+ " %", value: it };
   });

  useEffect(() =>{
    if(category){
      logging.debug(`Edition categorie ${category}`);
      const topics = categories.filter(it => it.category === category)[0].topics??[];
      setConfigurationTopics([...topics]);
    }
  }, [category])

  useEffect(() => {
    if (id && id !== "edit") {
      dispatch(fetchDetailPlate(id));
    } else {
      form.resetFields();
      form.setFieldValue("subCategory", "");
      form.setFieldValue('topicsConfiguration', []);
      form.setFieldValue("vat", "");
      setThePlate({
        hasCuissonMode: false,
        hasTopics: false,
        hasSauce: false,
        favorite: false,
        hasIceScream: false,
        name: "",
        prize: null,
        comment: "",
        subCategory: "",
        automaticallyAdd: false,
        state: "ENABLE",
        topics: [],
        vat: ''
      });
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (plateDetail && id === plateDetail.id) {
      form.resetFields();
      setThePlate({
        hasCuissonMode: plateDetail.hasCuissonMode,
        hasTopics: plateDetail.hasTopics,
        hasSauce: plateDetail.hasSauce,
        hasIceScream: plateDetail.hasIceScream,
        name: plateDetail.name,
        subCategory: plateDetail.subCategory?.replaceAll(' ', '-'),
        favorite: plateDetail.favorite,
        prize: plateDetail.prize,
        comment: plateDetail.comment,
        id: id,
        automaticallyAdd: plateDetail.automaticallyAdd,
        hasIce: plateDetail.hasIce,
        state: plateDetail.state === 'ENABLE' || plateDetail.state === undefined ? 'ENABLE' : 'DISABLE',
        topics: plateDetail.topics,
       
      });
      setSelectSubCategory([plateDetail.subCategory!.replaceAll(" ", "-")]);
      form.setFieldsValue(plateDetail);
      form.setFieldValue("subCategory", [plateDetail.subCategory?.replaceAll(" ", "-")]);
      form.setFieldValue("topicsConfiguration", plateDetail.topics ?? []);
      //form.setFieldValue("vat", plateDetail.vat || "")

      setLoading(false);
    }
  }, [plateDetail]);

  const hasTopicConfiguration = (topic:TopicModel) =>{
    const currentConfiguration = form.getFieldValue('topicsConfiguration') as TopicModel[];
    return currentConfiguration.filter(it => it.subName === topic.subName).length > 0;
  }
 
  const selectConfigurationTopics = (topic:TopicModel, state:boolean) => {
    console.log(topic.subName, state);
    const currentConfiguration = form.getFieldValue('topicsConfiguration') as TopicModel[];
    if(state){
      form.setFieldValue('topicsConfiguration', [...currentConfiguration, topic]);
    }else{
      form.setFieldValue('topicsConfiguration', currentConfiguration.filter(it => it.subName !== topic.subName));
    }
  };

  const onSubmit = async () => {
    const plate: PlateDetail = form.getFieldsValue();
    if (id !== undefined && id !== "edit") {
      plate["id"] = id;
    } else {
      plate["id"] = uuidv4();
    }
    plate["name"] = form.getFieldValue("name");
   

    const sub = form.getFieldValue("subCategory")[0];
    if (sub) {
      plate["subCategory"] = pickerData.filter((it) => it.value === sub).map((it) => it.label)[0];
    }

    plate["category"] = category;
    plate['topics'] = form.getFieldValue('topicsConfiguration') as TopicModel[];
    plate["state"] = plate["state"] ? "ENABLE" : "DISABLE";
    try {
      await savePlate(plate);
      dispatch(addPlate(plate));
      navigate(urlBack);
    } catch (e) {
      console.log(e);
    }
  };

 

  return (
    <>
      {loading && <p>Loading...</p>}
      {!loading && (
        <Form
          key={id}
          layout='vertical'
          mode='card'
          form={form}
          initialValues={thePlate}
          footer={
            <Grid columns={2} gap={4}>
              <Button block color='danger' size='large' onClick={() => navigate(urlBack)}>
                Annuler
              </Button>
              <Button block type='submit' color='success' size='large' onClick={() => onSubmit()}>
                Enregitrer
              </Button>
            </Grid>
          }
        >
          <Form.Item label='Plat' name='name'>
            <Input placeholder='Nom du plat/dessert' />
          </Form.Item>
          <Form.Item label='Sous catégorie' name='subCategory'>
            <Selector className='sub-category' columns={2} options={pickerData} multiple={false} />
          </Form.Item>
          <Form.Header>Caractèristiques</Form.Header>
          {configurationTopics &&
            configurationTopics.map((it) => (
              <Form.Item label={it.label} layout='horizontal' childElementPosition='right' key={it.subName}>
                <Switch
                  uncheckedText='Non'
                  checkedText='Oui'
                  defaultChecked={hasTopicConfiguration(it)}
                  onChange={(state) => selectConfigurationTopics(it, state)}
                />
              </Form.Item>
            ))}
          {hasCooking && (
            <Form.Item label='Appoint de cuisson' layout='horizontal' childElementPosition='right' name='hasCuissonMode'>
              <Switch uncheckedText='Non' checkedText='Oui' defaultChecked={thePlate?.hasCuissonMode} />
            </Form.Item>
          )}
          {hasTopic && (
            <Form.Item label='Accompagnement legumes' layout='horizontal' childElementPosition='right' name='hasTopics'>
              <Switch uncheckedText='Non' checkedText='Oui' defaultChecked={thePlate?.hasTopics} />
            </Form.Item>
          )}
          {hasSauce && (
            <Form.Item label='Sauce' layout='horizontal' childElementPosition='right' name='hasSauce'>
              <Switch uncheckedText='Non' checkedText='Oui' defaultChecked={thePlate?.hasSauce} />
            </Form.Item>
          )}
          {hasIceScream && (
            <Form.Item label='Glace' layout='horizontal' childElementPosition='right' name='hasIceScream'>
              <Switch uncheckedText='Non' checkedText='Oui' defaultChecked={thePlate?.hasIceScream} />
            </Form.Item>
          )}
          {hasIce && (
            <Form.Item label='Glaçon' layout='horizontal' childElementPosition='right' name='hasIce'>
              <Switch uncheckedText='Non' checkedText='Oui' defaultChecked={thePlate?.hasIce} />
            </Form.Item>
          )}
          <Form.Item label='Favori' layout='horizontal' childElementPosition='right' name='favorite'>
            <Switch uncheckedText='Non' checkedText='Oui' defaultChecked={thePlate?.favorite} />
          </Form.Item>
          <Form.Item label='Cumulatif' layout='horizontal' childElementPosition='right' name='automaticallyAdd'>
            <Switch uncheckedText='Non' checkedText='Oui' defaultChecked={thePlate?.automaticallyAdd} />
          </Form.Item>
          <Form.Item label='Actif' layout='horizontal' childElementPosition='right' name='state'>
            <Switch uncheckedText='Non' checkedText='Oui' defaultChecked={thePlate?.state === 'ENABLE'} />
          </Form.Item>
          <Form.Item label='Remarques' name='comment'>
            <TextArea placeholder='Remarques' autoSize={{ minRows: 3, maxRows: 5 }}  defaultValue=''/>
          </Form.Item>
          <Form.Header>Tarif</Form.Header>
          <Form.Item label='TVA' name='vat'>
            <Selector className='sub-category' columns={2} options={vatData} multiple={false} />
          </Form.Item>
          <Form.Item name='prize' label='Prix (TTC)'>
            <Input placeholder='Prix' type='number' defaultValue='' />
          </Form.Item>
        </Form>
      )}
    </>
  );
};
